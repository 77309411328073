import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Divider,
} from '@mui/material';
import {
  Agency,
  Brand,
  ChangeEventType,
  InvitationErrors,
  InviteInfo,
  Salesperson,
} from '../../types';
import AgencyLogoField from '../AgencyLogoField';
import { PrimaryButton } from '../Buttons';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import {
  createAgency,
  editAgency,
  getAgencyUsers,
  getSingleAgency,
} from '../../services/agency';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createExpressTheme, editExpressTheme } from '../../services/theme';
import {
  ADMIN,
  EDIT_THEME,
  IS_DIY_ADZ,
  ResizerReturnType,
  SUPER_ADMIN,
  resizeFile,
} from '../../utils';
import { AuthContext, ExpressThemeContext } from '../../context';
import { deleteLogo, uploadLogo } from '../../services/upload';
import PageHeader from '../PageHeader';
import { XsOnly } from '../../utils/breakpoints';
import { MuiTelInput } from 'mui-tel-input';
import CircularLoading from '../CircularLoading';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAgencies,
  setAgency,
  setBrands,
  setLocation,
  setLocations,
  toggleAlert,
} from '../../redux/actions';
import ConfirmNavigationModal, { usePrompt } from '../Navigation/RoutePrompt';
import AgencyUserForm from './AgencyUserForm';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import InviteModal from '../Modal/InviteModal';
import { humanizeString } from '../../utils/stringModifier';
import { inviteSalesperson } from '../../services/invitation';
import { RiUserAddLine } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import { emailAddressPattern } from '../../utils/validation';

const AgencyForm: React.FC = () => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    clearErrors,
  } = useForm({ mode: 'onSubmit' });
  const agency = useSelector((state: any) => state?.agency?.agency);
  const agencies: Agency[] =
    useSelector((state: any) => state?.agencies?.agencies) || [];
  const brands: Brand[] =
    useSelector((state: any) => state?.brands?.brands) || [];
  const locations: Brand[] =
    useSelector((state: any) => state?.locations?.locations) || [];
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const [searchParams] = useSearchParams();
  const agencyId: string = searchParams.get('agencyId') || null;
  const { dispatch } = useContext(ExpressThemeContext);
  const pathLocation = useLocation();
  const pathNames = pathLocation.pathname.split('/');
  const isEdit = pathNames.includes('edit');
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const { state } = useContext(AuthContext);
  const role = state.role;
  const isAdmin = role === ADMIN;
  const isSuperAdmin = role === SUPER_ADMIN;
  const roleBasedId = state.roleBasedId;
  const capabilities = state.capabilities;

  const MAX_USER_COUNT: number = 10;

  const [formValues, setFormValues] = useState<Agency>({
    agency: '',
    name: '',
    email: '',
    cell: '',
    url: '',
    theme: {
      theme: '',
      properties: {
        bgColor: '#000000',
        txtColor: '#ffffff',
      },
    },
    logo: {
      url: '',
      public_id: '',
    },
    fbLoginEmails: [],
    page: null,
    allowSetupPayment: false,
    diy: IS_DIY_ADZ,
  });
  const [fbLoginEmails, setFbLoginEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openConfirmNavModal, setOpenConfirmNavModal] =
    useState<boolean>(false);
  const [autoUnblockingTx, setAutoUnblockingTx] = useState<any>(null);
  const [agencyUsers, setAgencyUsers] = useState<Salesperson[]>([]);
  const [formCount, setFormCount] = useState<number>(1);
  const [showMultipleUserForms, setShowMultipleUserForms] = useState<boolean[]>(
    [false],
  );
  const [invitationInfo, setInvitationInfo] = useState<InviteInfo>({
    name: '',
    email: '',
    id: '',
    role: '',
  });
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [invitationErrors, setInvitationErrors] = useState<InvitationErrors>({
    email: '',
  });
  const [inviteUserLoading, setInviteUserLoading] = useState<boolean>(false);

  usePrompt(setOpenConfirmNavModal, setAutoUnblockingTx, isDirty);

  useEffect(() => {
    setFbLoginEmails(formValues.fbLoginEmails);
  }, [formValues]);

  useEffect(() => {
    if (isEdit && agencyId) getAgency();
  }, [agencyId, isEdit]);

  useEffect(() => {
    getUsers();
  }, [agencyId]);

  const getAgency = async () => {
    setFetchLoading(true);
    try {
      const agency: Agency = await getSingleAgency(agencyId);

      setFormValues({
        ...formValues,
        agency: agency.agency,
        name: agency.name,
        email: agency.email,
        cell: agency.cell,
        url: agency.url,
        logo: agency.logo,
        theme: {
          ...formValues.theme,
          theme: agency.theme.theme,
          properties: { ...agency.theme.properties },
        },
        fbLoginEmails: agency.fbLoginEmails,
        page: agency.page,
        allowSetupPayment: agency.allowSetupPayment || false,
        diy: agency.diy || false,
        users: agency.users || [],
      });
      setFormCount(agency.users.length || 1);
      setFetchLoading(false);
    } catch (error: any) {
      setFetchLoading(false);
      console.log(error);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await getAgencyUsers();

      setAgencyUsers(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleCloseInviteModal = () => {
    setInvitationErrors({
      ...invitationErrors,
      email: '',
    });
    setOpenInviteModal(false);
  };

  const handleOnSubmit = async () => {
    setIsDirty(false);
    try {
      setLoading(true);
      if (isEdit) {
        const { updatedAgency, message } = await editAgency(agencyId, {
          ...formValues,
          diy: IS_DIY_ADZ,
        });

        if (!IS_DIY_ADZ) {
          const { updatedTheme } = await editExpressTheme(
            formValues.url.split('.')[0].toLowerCase(),
            {
              ...formValues.theme.properties,
              logo: formValues.logo,
            },
          );

          if (
            window.location.hostname.split('.')[0] === updatedAgency.theme.theme
          ) {
            dispatch({
              type: EDIT_THEME,
              payload: {
                theme: updatedTheme.theme,
                properties: updatedTheme.properties,
              },
            });
          }
        }

        reduxDispatch(
          toggleAlert({
            toggle: true,
            message,
          }),
        );

        let tempAgencies: Agency[] = [];
        agencies.forEach((item: Agency) => {
          if (item._id === updatedAgency._id) {
            item = updatedAgency;
          }

          tempAgencies = [...tempAgencies, item];
        });

        if (agency) {
          reduxDispatch(setAgency(updatedAgency));
        }

        let tempBrands: Brand[] = [];
        brands.forEach((item: Brand) => {
          if (item?.agency?._id === updatedAgency._id) {
            item.agency.logo = updatedAgency?.logo;
          }

          tempBrands = [...tempBrands, item];
        });

        if (agency) {
          reduxDispatch(setAgency(updatedAgency));
        }

        let tempLocations: Brand[] = [];
        if (locations.length > 0) {
          locations.forEach((item: Brand) => {
            if (item?.agency?._id === updatedAgency._id) {
              item.agency.logo = updatedAgency?.logo;
            }

            tempLocations = [...tempLocations, item];
          });
        }

        if (location) {
          let tempLocation: Brand = { ...location };
          tempLocation.agency.logo = updatedAgency?.logo;

          reduxDispatch(setLocation(tempLocation));
        }

        if (brand?.agency?._id === updatedAgency._id) {
          let tempBrand: Brand = { ...brand };
          tempBrand.agency.logo = updatedAgency?.logo;

          reduxDispatch(setLocation(tempBrand));
        }

        reduxDispatch(setAgencies(tempAgencies));
        reduxDispatch(setBrands(tempBrands));
        reduxDispatch(setLocations(tempLocations));
      } else {
        const { agency, message } = await createAgency({
          ...formValues,
          theme: {
            theme: formValues.url.split('.')[0].toLowerCase(),
            properties: {
              ...formValues.theme.properties,
            },
          },
          diy: IS_DIY_ADZ,
        });

        if (!IS_DIY_ADZ) {
          const res = await createExpressTheme({
            theme: formValues.url.split('.')[0].toLowerCase(),
            properties: {
              ...formValues.theme.properties,
              logo: formValues.logo,
            },
          });

          if (window.location.hostname.split('.')[0] === agency.theme.theme) {
            dispatch({
              type: EDIT_THEME,
              payload: {
                theme: res.theme.theme,
                properties: res.theme.properties,
              },
            });
          }
        }

        reduxDispatch(
          toggleAlert({
            toggle: true,
            message,
          }),
        );

        reduxDispatch(setAgencies([...agencies, agency]));
      }

      navigate(IS_DIY_ADZ ? '/agenciez' : '/agencies');
    } catch (error: any) {
      setIsDirty(true);
      const errorMsg = errorMessageParser(error);
      reduxDispatch(
        toggleAlert({
          toggle: true,
          message: errorMsg,
          type: 'error',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleInviteAgencyUser = async (email: string) => {
    try {
      setInviteUserLoading(true);

      const response = await inviteSalesperson({
        email,
        clientUri: window.location.host,
        diy: IS_DIY_ADZ,
      });

      setOpenInviteModal(false);

      let users: any[] = [];
      formValues?.users.forEach((user: any) => {
        if (user.email === email) {
          user.invitedAt = response?.data?.invitedAt;
        }

        users = [...users, user];
      });

      setFormValues({ ...formValues, users });

      dispatch(
        toggleAlert({
          toggle: true,
          message: response?.message,
        }),
      );
    } catch (error: any) {
      const err = error.response.data;

      setInvitationErrors({
        ...invitationErrors,
        email: err.email,
      });
    } finally {
      setInviteUserLoading(false);
    }
  };

  const handleUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    const file = e.target.files[0];
    try {
      setUploadLoading(true);
      const image: ResizerReturnType = await resizeFile(file);
      const res = await uploadLogo(image);
      if (res) {
        setFormValues({ ...formValues, logo: res.logo });
        setUploadLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: res.message,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setUploadLoading(false);
    }
  };

  const handleRemoveLogo = async (public_id: string) => {
    setIsDirty(true);
    try {
      setUploadLoading(true);
      const res = await deleteLogo(public_id);
      if (res) {
        setFormValues({ ...formValues, logo: { url: '', public_id: '' } });
        setUploadLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: res.message,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setUploadLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    clearErrors(e.target.name);
    setIsDirty(true);

    if (e.target.name === 'bgColor') {
      setFormValues({
        ...formValues,
        theme: {
          ...formValues.theme,
          properties: {
            ...formValues.theme.properties,
            bgColor: e.target.value,
          },
        },
      });
    } else if (e.target.name === 'txtColor') {
      setFormValues({
        ...formValues,
        theme: {
          ...formValues.theme,
          properties: {
            ...formValues.theme.properties,
            txtColor: e.target.value,
          },
        },
      });
    } else if (e.target.name === 'agency') {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
        theme: { ...formValues.theme, theme: e.target.value.toLowerCase() },
      });
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };

  const handleOnChangeNumber = (newValue: string) => {
    setIsDirty(true);
    setFormValues({ ...formValues, cell: newValue.split(' ').join('') });
  };

  const handleAddFbEmails = async (value: string[]) => {
    setIsDirty(true);
    let newFormValue: Agency = { ...formValues };

    newFormValue = { ...newFormValue, fbLoginEmails: value };

    await setFormValues(newFormValue);
  };

  const handleConfirmNavigation = () => {
    autoUnblockingTx.retry();
    setOpenConfirmNavModal(false);
  };

  const handleCancelNavigation = () => {
    setOpenConfirmNavModal(false);
  };

  const handleOnCloseUserForm = (index: number) => {
    let userForms: boolean[] = [...showMultipleUserForms];

    userForms[index] = false;

    setShowMultipleUserForms(userForms);
  };

  const handleOnOpenUserForm = (index: number) => {
    setIsDirty(true);
    let userForms: boolean[] = [...showMultipleUserForms];

    userForms[index] = true;

    setShowMultipleUserForms(userForms);
  };

  const handleRemoveUser = (index: number) => {
    setIsDirty(true);
    let showForms: boolean[] = [...showMultipleUserForms];
    let users: Salesperson[] = [...(formValues?.users || [])];
    if (users[index]) {
      users.splice(index, 1);
      setFormValues({ ...formValues, users });
    }

    showForms.splice(index, 1);
    setFormCount((prev) => prev - 1);
  };

  const handleOpenInviteModal = (info: InviteInfo) => {
    setOpenInviteModal(true);
    setInvitationInfo({
      ...invitationInfo,
      name: info.name,
      email: info.email,
      id: info.id,
      role: info.role,
    });
  };

  const handleAddUser = () => {
    setIsDirty(true);
    setShowMultipleUserForms([...showMultipleUserForms, false]);
    setFormCount((prev) => prev + 1);
  };

  const multipleUserForm = () => {
    let components: any[] = [];

    for (let i = 0; i < formCount; i++) {
      const disabled = (formValues?.users || [])[i]?._id === roleBasedId;

      components = [
        ...components,
        <>
          <Grid item key={i + 1} xs={12}>
            <p className={styles.label}>{`User #${i + 1}`}</p>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={disabled}
                  id={`user-${i + 1}`}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option._id}>
                        {`${option.salespersonId}. ${option.name}`}
                      </li>
                    );
                  }}
                  options={agencyUsers.sort(
                    (a, b) => -b.name?.localeCompare(a.name),
                  )}
                  value={(formValues?.users || [])[i] || null}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Name"
                      placeholder="Select a user"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      //error={errors?.users && i === 0 ? true : false}
                      //helperText={errors?.users && i === 0 ? errors?.users : ''}
                    />
                  )}
                  disableClearable
                  onChange={async (e: any, value: any) => {
                    setIsDirty(true);
                    let users: any[] = [...(formValues?.users || [])];
                    if (users.length === 0) {
                      users = [...users, value];
                    } else {
                      users[i] = value;
                    }

                    setFormValues({ ...formValues, users });
                  }}
                  getOptionDisabled={(option) => {
                    const ids = formValues?.users?.map((user: any) => {
                      return user._id;
                    });
                    return ids?.includes(option._id);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  justifyContent: 'left',
                  alignItems: 'left',
                  display: 'flex',
                }}
              >
                {!showMultipleUserForms[i] ? (
                  <PrimaryButton
                    title={`${
                      (formValues?.users || [])[i] ? 'Edit' : 'Create'
                    } User`}
                    type="button"
                    variant="text"
                    startIcon={<PersonAddAltOutlinedIcon />}
                    fontSize="12px"
                    handleOnClick={() => handleOnOpenUserForm(i)}
                    disabled={disabled}
                  />
                ) : null}

                <PrimaryButton
                  title="Remove User"
                  type="button"
                  variant="text"
                  startIcon={<GroupRemoveOutlinedIcon />}
                  fontSize="12px"
                  handleOnClick={() => handleRemoveUser(i)}
                  disabled={disabled}
                />

                {!(formValues.users || [])[i]?._id ||
                (formValues.users || [])[i]?.user?.email ? (
                  <PrimaryButton
                    title="Invite Accepted"
                    type="button"
                    variant="text"
                    startIcon={<ContactMailOutlinedIcon />}
                    fontSize="12px"
                    disabled
                  />
                ) : (
                  <PrimaryButton
                    title={
                      (formValues.users || [])[i]?.invitedAt
                        ? 'Resend Invite'
                        : 'Invite User'
                    }
                    type="button"
                    variant="text"
                    startIcon={<ContactMailOutlinedIcon />}
                    fontSize="12px"
                    handleOnClick={() => {
                      handleOpenInviteModal({
                        ...formValues?.users[i],
                      } as InviteInfo);
                    }}
                    disabled={disabled}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {showMultipleUserForms[i] ? (
            <Grid item xs={12}>
              <AgencyUserForm
                agencyUsers={agencyUsers}
                setAgencyUsers={setAgencyUsers}
                agencyFormValues={formValues}
                setAgencyFormValues={setFormValues}
                onClose={handleOnCloseUserForm}
                index={i}
                edit={(formValues?.users || [])[i] ? true : false}
                salespersonId={
                  (formValues?.users || [])[i]
                    ? (formValues?.users || [])[i]._id
                    : null
                }
                currentUserCapabilities={capabilities}
                isAdmin={isSuperAdmin || isAdmin}
                open={showMultipleUserForms[i]}
                agencyId={!!agencyId ? agencyId : null}
              />
            </Grid>
          ) : null}
        </>,
      ];
    }

    return components;
  };

  return (
    <Box
      sx={{ width: '100%' }}
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
    >
      <CircularLoading loading={fetchLoading} />

      <PageHeader title={isEdit ? 'Edit Agency' : 'Add New Agency'} />

      {isSuperAdmin || isAdmin ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              Details
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...register('agency', {
                required: true,
              })}
              fullWidth
              required
              variant="standard"
              type="text"
              id="agency"
              name="agency"
              label="Agency Name"
              onChange={handleOnChange}
              value={formValues.agency}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors?.agency}
              helperText={errors?.agency && 'Agency name is required'}
              autoFocus
            />
          </Grid>

          {!IS_DIY_ADZ ? (
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('name', {
                  required: true,
                })}
                fullWidth
                required
                variant="standard"
                type="text"
                id="name"
                name="name"
                label="Agency Holder Name"
                onChange={handleOnChange}
                value={formValues.name}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!errors?.name}
                helperText={errors?.name && 'Agency holder name is required'}
              />
            </Grid>
          ) : null}

          {!IS_DIY_ADZ ? (
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('email', {
                  required: true,
                  pattern: emailAddressPattern,
                })}
                fullWidth
                required
                variant="standard"
                type="email"
                id="email"
                name="email"
                label="Email"
                onChange={handleOnChange}
                value={formValues.email}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!errors?.email}
                helperText={
                  errors?.email?.message ||
                  (errors?.name && 'Email is required')
                }
              />
            </Grid>
          ) : null}

          {!IS_DIY_ADZ ? (
            <Grid item xs={12} sm={6}>
              <MuiTelInput
                fullWidth
                required
                id="cell"
                name="cell"
                label="Phone Number"
                variant="standard"
                onChange={(newValue: string) => handleOnChangeNumber(newValue)}
                value={formValues?.cell}
                InputLabelProps={{ shrink: true }}
                size="small"
                onlyCountries={['US']}
                defaultCountry="US"
                error={errors?.cell ? true : false}
                helperText={errors?.cell ? errors.cell : ''}
              />
            </Grid>
          ) : null}

          {!IS_DIY_ADZ ? (
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('url', {
                  required: true,
                })}
                fullWidth
                required
                variant="standard"
                type="text"
                id="url"
                name="url"
                label="URL"
                onChange={handleOnChange}
                value={formValues.url}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!errors?.url}
                helperText={errors?.url && 'URL is required'}
              />
            </Grid>
          ) : null}

          {!IS_DIY_ADZ ? (
            <Grid item xs={12}>
              <Autocomplete
                id="fbLoginEmails"
                multiple
                disableClearable
                freeSolo
                options={[]}
                value={fbLoginEmails}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Facebook Login Emails"
                    placeholder="Enter Facebook Login Emails"
                    name="fbLoginEmails"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(e: any, value: any[]) => {
                  handleAddFbEmails(value);
                }}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      ) : null}

      {IS_DIY_ADZ &&
      (isSuperAdmin ||
        isAdmin ||
        (!(isSuperAdmin || isAdmin) && capabilities.agencyAdmin)) ? (
        <Grid container spacing={2} my={1}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Users
            </Typography>
          </Grid>

          {multipleUserForm()}

          <Grid item xs={12}>
            {formCount !== MAX_USER_COUNT ? (
              <PrimaryButton
                title="Add User"
                type="button"
                handleOnClick={handleAddUser}
                startIcon={<RiUserAddLine />}
              />
            ) : null}
          </Grid>
        </Grid>
      ) : null}

      {isSuperAdmin || isAdmin ? (
        <Grid container spacing={2} my={1}>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              Logo
            </Typography>
          </Grid>

          <AgencyLogoField
            logo={formValues.logo}
            handleUploadImage={handleUploadImage}
            handleRemoveLogo={handleRemoveLogo}
            uploadLoading={uploadLoading}
          />
        </Grid>
      ) : null}

      <div className={`${styles.action} ${styles['-unsticky']}`}>
        <PrimaryButton
          title={isEdit ? 'Save' : 'Add'}
          loading={loading}
          type="button"
          size="large"
          rounded
          handleOnClick={handleSubmit(handleOnSubmit)}
        />

        <PrimaryButton
          title="Cancel"
          type="button"
          handleOnClick={() => navigate(IS_DIY_ADZ ? '/agenciez' : '/agencies')}
          theme="red"
          variant="text"
          size="large"
        />
      </div>

      <ConfirmNavigationModal
        open={openConfirmNavModal}
        onClose={handleCancelNavigation}
        onConfirm={handleConfirmNavigation}
      />

      <InviteModal
        title={`Invite as ${humanizeString(invitationInfo.role)}`}
        subtitle={`Are you sure you want to invite ${
          invitationInfo.name
        } as ${humanizeString(invitationInfo.role)}?`}
        open={openInviteModal}
        handleClose={handleCloseInviteModal}
        invitationInfo={invitationInfo}
        handleInvite={handleInviteAgencyUser}
        loading={inviteUserLoading}
        errors={invitationErrors}
      />
    </Box>
  );
};

export default AgencyForm;
