import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import React, { useEffect, useRef, useState, useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ChangeEventType } from '../../types';
import { SUPER_ADMIN, ADMIN, DEFAULT_COLOR_THEME } from '../../utils';
import ScrollButton from './ScrollButton';
import styles from '../../assets/styles/pages/ChatDialog.module.scss';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

import PageHeader from '../../components/PageHeader';
import { FaLocationArrow } from 'react-icons/fa';
import { zChatMessage } from '../../types';
import { getZChats, sendZChat, clearZChats } from '../../services/aiChat';
import { AuthContext } from '../../context';
const ZChatDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { state } = useContext(AuthContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [messages, setMessages] = useState<zChatMessage[]>([]);
  const [message, setMessage] = useState<string>('');
  const [showScrollBtn, setShowScrollBtn] = useState<boolean>(false);
  const [isSendingMess, setIsSendingMess] = useState<boolean>(false);
  const [sendingMess, setSendingMess] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(false);
  const scrollRef: any = useRef();
  const boxRef: any = useRef();

  const handleScrolls = () => {
    const box = boxRef.current;

    if (
      box.scrollTop === 0 &&
      messages.length &&
      messages.length <= 19 &&
      !loading &&
      hasMore &&
      !loadingMore
    ) {
      setLoadingMore(true);

      loadMoreMessage();
    }
  };

  useEffect(() => {
    const box = boxRef.current;
    box.addEventListener('scroll', handleScrolls);
    return () => {
      box.removeEventListener('scroll', handleScrolls);
    };
  }, [messages]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const list = await getZChats();

        setMessages(list?.data?.messages);
        setLoading(false);
        handleScrollDown();

        const hasMore: Boolean = list?.data?.hasMore || false;

        if (hasMore) {
          setHasMore(true);
        }
      } catch (error: any) {
        setLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: error?.response?.data?.message || 'Something went wrong',
            type: 'error',
          }),
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const clearMessages = async () => {
    setLoading(true);
    try {
      await clearZChats();
      setMessages([]);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    setSendingMess(message);
    setIsSendingMess(true);
    const msg = message;
    setMessage('');
    setLoading(true);
    setTimeout(() => {
      handleScrollDown();
    }, 500);

    try {
      const list = await sendZChat(msg);

      const msgs = messages || [];

      let newArMes: any = list?.data?.messages || [];

      let combinedArray = newArMes
        .concat(msgs)
        .reduce((accumulator: any[], current: zChatMessage) => {
          const existingItem = accumulator.find(
            (item) => item.id === current._id,
          );
          if (!existingItem) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);

      setIsSendingMess(false);
      setSendingMess('');
      setMessages(combinedArray);

      setLoading(false);
      setMessage('');
      handleScrollDown();
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error?.response?.data?.message || 'Something went wrong',
          type: 'error',
        }),
      );
      setIsSendingMess(false);
      setSendingMess('');
      setMessage('');
      setLoading(false);
      handleScrollDown();
    } finally {
      setLoading(false);
    }
  };

  const loadMoreMessage = async () => {
    try {
      const len = messages.length;

      const list = await getZChats(len);

      let msgs = messages || [];

      let newArMes: any[] = list?.data?.messages || [];

      let combinedArray = msgs
        .concat(newArMes)
        .reduce((accumulator: any[], current: zChatMessage) => {
          const existingItem = accumulator.find(
            (item) => item.id === current._id,
          );
          if (!existingItem) {
            accumulator.push(current);
          }

          return accumulator;
        }, []);

      setMessages(combinedArray);

      const hasMr: boolean = list?.data?.hasMore || false;

      setHasMore(hasMr);

      setLoadingMore(false);
    } catch (error) {
      setLoadingMore(false);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    setMessage(e.target.value);
  };

  const handleScrollDown = () => {
    if (scrollRef.current && scrollRef.current.offsetTop) {
      boxRef.current.scrollTo({
        top: scrollRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const scrollData = scrollHeight - scrollTop - clientHeight;

    if (Math.abs(scrollData) < 2) {
      setShowScrollBtn(false);
    } else {
      setShowScrollBtn(true);
    }

    if (scrollTop === 0 && hasMore && !loading) {
      setLoadingMore(true);

      loadMoreMessage();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !loading) {
      setLoading(true);
      sendMessage();
    }
  };

  const messageContent = (isOwnMessage: boolean, message: any) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const boldMessage = message.replace(boldRegex, '<strong>$1</strong>');

    return (
      <div
        className={`${styles.message} ${isOwnMessage ? styles['-own'] : ''}`}
      >
        <div
          className={`${styles.content} ${isOwnMessage ? styles['-own'] : ''}`}
        >
          <Typography
            component="p"
            sx={{
              color: isOwnMessage ? '#FFFFFF' : '#484848',
              pt: 1,
              fontSize: '12px',
              whiteSpace: 'pre-line',
            }}
            dangerouslySetInnerHTML={{ __html: boldMessage }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.home}>
      <div className={styles.base}></div>
      <div className={styles.header} style={{ marginTop: '10px' }}>
        <Box
          component="div"
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          {(isAdmin || isSuperAdmin) && messages.length !== 0 ? (
            <Box sx={{ position: 'absolute', left: '55%' }}>
              <Button
                onClick={() => clearMessages()}
                variant="contained"
                type="button"
                sx={{
                  color: 'white',
                  backgroundColor: 'white',
                  boxShadow: '0',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'white',
                    boxShadow: '0',
                  },
                }}
              >
                Clear History
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <PageHeader title="Ask Z" />
      </div>

      <Box
        component="div"
        sx={{
          maxWidth: '100%',
          mx: 'auto',
          height: '100%',
        }}
        className={styles.chat}
      >
        <Box
          component="div"
          mt={3}
          mb={1}
          sx={{
            border: `1px solid ${DEFAULT_COLOR_THEME}`,
            borderRadius: '5px',
            position: 'relative',
          }}
        >
          <Box>
            <Box
              ref={boxRef}
              onScroll={handleScroll}
              sx={{
                minHeight: 'calc(120vh - 410px)',
                maxHeight: 'calc(120vh - 410px)',
                overflowY: 'scroll',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                  width: '10px',
                },
                '&::-webkit-scrollbar-track': {
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                },

                '&::-webkit-scrollbar-thumb': {
                  background: DEFAULT_COLOR_THEME,
                  borderRadius: '18px',

                  '&:hover': {
                    background: DEFAULT_COLOR_THEME,
                  },
                },
              }}
            >
              {(loading || loadingMore) && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    left: '50%',
                    zIndex: 100,
                  }}
                >
                  <CircularProgress color="secondary" size={30} />
                </Box>
              )}

              <Box
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '50%',
                  zIndex: 100,
                }}
              >
                {showScrollBtn && (
                  <ScrollButton handleScrollDown={handleScrollDown} />
                )}
              </Box>

              {messages.length !== 0
                ? messages
                    .slice()
                    .reverse()
                    .map((msg, index) => (
                      <div key={index}>
                        <Box
                          component="div"
                          py={1.5}
                          px={2}
                          display="flex"
                          alignItems="center"
                          justifyContent={
                            msg.from === 'User' ? 'flex-end' : 'flex-start'
                          }
                          key={index}
                          ref={scrollRef}
                        >
                          {messageContent(
                            msg.from === 'User' ? true : false,
                            msg?.message || '',
                          )}
                        </Box>
                      </div>
                    ))
                : null}

              {isSendingMess && (
                <>
                  <div key={messages.length + 1}>
                    <Box
                      component="div"
                      py={1.5}
                      px={2}
                      display="flex"
                      alignItems="center"
                      justifyContent={'flex-end'}
                      key={messages.length + 1}
                      ref={scrollRef}
                    >
                      {messageContent(true, sendingMess || '')}
                    </Box>
                  </div>
                </>
              )}
            </Box>
          </Box>
        </Box>

        <Box component="form" className={`${styles.form} ${styles['-dialog']}`}>
          <Grid container spacing={2}>
            <Grid item xs={12} position="relative">
              <TextField
                fullWidth
                multiline
                autoComplete="off"
                maxRows={1}
                type="text"
                id="message"
                name="message"
                label="Question:"
                InputProps={{
                  sx: { paddingRight: 5.5, overflowY: 'hidden' },
                }}
                onChange={handleOnChange}
                value={message}
                InputLabelProps={{ shrink: true }}
                size="medium"
                onKeyPress={handleKeyPress}
                disabled={loading ? true : false}
              />

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls="chat-settings"
                aria-haspopup="true"
                onClick={(e) => {
                  if (!loading) sendMessage();
                }}
                color="inherit"
                sx={{
                  width: '50px',
                  height: '50px',
                  position: 'absolute',
                  top: '19px',
                  right: '20px',
                }}
              >
                <FaLocationArrow
                  style={{
                    color: '#1976d2',
                    fontSize: '30px',
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <div style={{ textAlign: 'center', width: '100%' }}>
        <Typography
          sx={{
            color: 'gray',
            fontSize: '8px',
          }}
        >
          Ask Z can make mistakes. Consider checking important information.
        </Typography>
      </div>
    </div>
  );
};
export default ZChatDialog;
