import React, { Dispatch, SetStateAction } from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridGroupNode,
} from '@mui/x-data-grid-premium';
import {
  AdLead,
  ClientTetherSalesCycleReport,
  ClientTetherSalesCycleReportData,
} from '../../../types';
import {
  ctSalesCycleColumns,
  ctSalesCycleColumnsByAds,
  ctSalesCycleGroupingColumn,
} from './ClientTetherSalesCycleReportTableColumns';
import { CustomLoadingOverlay } from '../../Loading';
import { arrayUniqueFilter } from '../../../utils/arrayFormatter';

interface ClientTetherSalesCycleReportTableProps {
  data: ClientTetherSalesCycleReport;
  loading: boolean;
  onOpenLeadDetails: (lead: AdLead) => void;
  expandedKeys: string[];
  setExpandedKeys: Dispatch<SetStateAction<string[]>>;
  isByAds?: boolean;
  setSelectedAdId: Dispatch<SetStateAction<string>>;
  setOpenAdDetails: Dispatch<SetStateAction<boolean>>;
}

const ClientTetherSalesCycleReportTable: React.FC<
  ClientTetherSalesCycleReportTableProps
> = ({
  data,
  loading,
  onOpenLeadDetails,
  isByAds,
  expandedKeys,
  setExpandedKeys,
  setSelectedAdId,
  setOpenAdDetails,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) =>
    row.group;

  const calculateSpend = (
    data: ClientTetherSalesCycleReportData,
    spend: number,
    totalLeads: number,
  ) => {
    const spendPerLead = totalLeads > 0 ? spend / totalLeads : 0;

    return (data?.leads || 0) * spendPerLead;
  };

  const calculateRoi = (
    data: ClientTetherSalesCycleReportData,
    spend: number,
  ) => {
    const totalSpend = !isByAds ? spend : data.spend;

    return parseInt(
      (((data.value - totalSpend) / totalSpend) * 100).toFixed(0),
    );
  };

  const rows = (data?.data || [])?.map(
    (o: ClientTetherSalesCycleReportData) => {
      return {
        ...o,
        roi: calculateRoi(
          o,
          calculateSpend(o, data?.totalSpend || 0, data?.totalLeads || 0),
        ),
        cpl: o.leads > 0 ? o.spend / o.leads : 0,
        spend: calculateSpend(o, data?.totalSpend || 0, data?.totalLeads || 0),
      };
    },
    [],
  );

  const cellBgFormatter = (params: any) => {
    if (isByAds) {
      if (params.row.level === 'ad') {
        return 'top';
      }

      if (params.row.level === 'sales_cycle') {
        return 'middle';
      }
    } else {
      if (params.row.level === 'sales_cycle') {
        return 'top';
      }
    }

    return '';
  };

  const renderColumns = () => {
    if (isByAds) {
      return ctSalesCycleColumnsByAds(onOpenLeadDetails, xsOnly);
    }

    return ctSalesCycleColumns(xsOnly);
  };

  return (
    <Box
      sx={{
        '& .top': {
          backgroundColor: '#B6c61a',
        },
        '& .middle': {
          backgroundColor: '#b9d870',
        },
      }}
    >
      <DataGridPremium
        initialState={{
          sorting: { sortModel: [{ field: 'order', sort: 'desc' }] },
        }}
        treeData
        groupingColDef={ctSalesCycleGroupingColumn(
          onOpenLeadDetails,
          isByAds,
          xsOnly,
        )}
        getTreeDataPath={getTreeDataPath}
        rows={loading ? [] : rows}
        columns={renderColumns()}
        autoHeight
        density="compact"
        hideFooterSelectedRowCount
        hideFooterRowCount
        hideFooter
        loading={loading}
        className={styles.table}
        disableRowSelectionOnClick
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        getRowId={(row) => row.id}
        getCellClassName={(params: any) => {
          return cellBgFormatter(params);
        }}
        slotProps={{
          baseIconButton: {
            onClick: () => {
              return false;
            },
          },
        }}
        isGroupExpandedByDefault={(row: GridGroupNode) =>
          expandedKeys.includes(row.groupingKey as string)
        }
        onCellClick={(params) => {
          if (params.row.level === 'lead' && !isByAds) {
            if (params.field === 'adName') {
              setSelectedAdId(params.row.adId);
              setOpenAdDetails(true);
            }

            if (params.field === '__tree_data_group__') {
              onOpenLeadDetails(params.row.lead);
            }
          }
        }}
        onRowClick={(params, event: any) => {
          if (event.target?.className?.animVal) {
            let groups: string[] = [...expandedKeys];

            const existingKey = groups.find(
              (key: any) =>
                key === params.row.group[params.row.group.length - 1],
            );

            if (existingKey) {
              groups = groups.filter((key: string) => key !== existingKey);
            } else {
              data?.data?.forEach((report: any) => {
                if (report.group.includes(params.row.group[0])) {
                  groups = [...groups, ...report.group];
                }
              });
            }

            setExpandedKeys(arrayUniqueFilter(groups));
          } else {
            if (isByAds && params.row.level === 'ad') {
              setSelectedAdId(params.row.id.split('-')[0]);
              setOpenAdDetails(true);
            }
          }
        }}
      />
    </Box>
  );
};

export default ClientTetherSalesCycleReportTable;
