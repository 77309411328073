import axios from 'axios';
import { apiBaseUrl } from './api';

export const getZChats = async (skip?: number) => {
  const skp = skip || 0;
  const data = await axios.get(`${apiBaseUrl}/ai/ask-z/messages?skip=${skp}`);

  return data;
};

export const sendZChat = async (message?: string) => {
  const msg = await axios.post(`${apiBaseUrl}/ai/ask-z/messages/send`, {
    message: message,
  });

  return msg;
};

export const clearZChats = async () => {
  const msg = await axios.delete(`${apiBaseUrl}/ai/ask-z/messages/clear`);

  return msg;
};
