import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Agency } from '../../../types';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { defaultProps } from '../../../utils/helpers/TableHelpers';

export const adminColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      width: 50,
      field: 'id',
      headerClassName: styles.header,
      headerName: 'ID',
      disableColumnMenu: true,
      sortingOrder: ['desc', 'asc'],
    },
    {
      ...defaultProps(isMobile, 180),
      field: 'agency',
      headerClassName: `${styles['header']}`,
      headerName: 'Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { agency } = params.row;

        return <div className={styles.name}>{agency}</div>;
      },
    },
    {
      field: 'invite',
      headerClassName: styles.header,
      headerName: 'Invite',
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params) => {
        const { handleOnClickInviteBtn, name, email, _id, withUser } =
          params.row;

        return (
          <span
            className={`${styles.invite} ${
              withUser ? `${styles['-disabled']}` : ''
            }`}
            onClick={(e) => {
              if (withUser) return;

              e.stopPropagation();
              handleOnClickInviteBtn({ name, email, _id });
            }}
          >
            Invite
          </span>
        );
      },
    },
    {
      field: 'action',
      headerClassName: styles.header,
      headerName: 'Action',
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params) => {
        const { _id, handleOnClickEdit, handleOnClickDelete } = params.row;

        const agency: Agency = {
          agency: params.row.agency,
          name: params.row.name,
          email: params.row.email,
          cell: params.row.cell,
          url: params.row.url,
          logo: params.row.logo,
          theme: {
            theme: params.row.theme.theme,
            properties: { ...params.row.theme.properties },
          },
          _id: params.row._id,
          fbLoginEmails: params.row.fbLoginEmails,
          page: {
            _id: params.row.page?._id,
            name: params.row.page?.name,
            accessToken: params.row.page?.accessToken,
            fbPageId: params.row.page?.fbPageId,
            imageUrl: params.row.page?.imageUrl,
          },
          allowSetupPayment: params.row.allowSetupPayment,
        };

        return (
          <div>
            <IconButton onClick={() => handleOnClickEdit(agency)} size="small">
              <ModeEditOutlineOutlinedIcon
                sx={{ fontSize: '18px', color: 'green' }}
              />
            </IconButton>

            {/**
           * Temporary Removed: Unused Feature
            <IconButton
              size="small"
              onClick={() => {
                if (user?.banned) {
                  handleUnbanAgency(params.row._id);
                } else {
                  handleBanAgency(params.row._id);
                }
              }}
            >
              {user?.banned ? (
                <CheckCircleOutlinedIcon
                  sx={{ fontSize: '18px', color: 'green' }}
                />
              ) : (
                <BlockIcon sx={{ fontSize: '18px', color: 'red' }} />
              )}
            </IconButton>
           */}

            <IconButton
              size="small"
              onClick={() => {
                handleOnClickDelete(_id);
              }}
            >
              <DeleteOutlineOutlinedIcon
                sx={{ fontSize: '18px', color: 'red' }}
              />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};

export const columns = (
  isMobile: boolean,
  canAddUser: boolean,
  isDiy: boolean,
) => {
  let columns: GridColDef[] = [
    {
      field: 'id',
      headerClassName: styles.header,
      headerName: 'ID',
      width: 50,
      disableColumnMenu: true,
      sortingOrder: ['desc', 'asc'],
    },
    {
      ...defaultProps(isMobile, 180),
      field: 'agency',
      headerClassName: styles.header,
      headerName: 'Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { agency } = params.row;

        return <div className={styles.name}>{agency}</div>;
      },
    },
  ];

  if (!isDiy) {
    columns = [
      ...columns,
      {
        field: 'invite',
        headerClassName: styles.header,
        headerName: 'Invite',
        hideSortIcons: true,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',

        renderCell: (params) => {
          const { handleOnClickInviteBtn, name, email, _id, withUser } =
            params.row;

          return (
            <span
              className={`${styles.invite} ${
                withUser ? `${styles['-disabled']}` : ''
              }`}
              onClick={(e) => {
                if (withUser) return;

                e.stopPropagation();
                handleOnClickInviteBtn({ name, email, _id });
              }}
            >
              Invite
            </span>
          );
        },
      },
    ];
  }

  if (canAddUser) {
    columns = [
      ...columns,
      {
        field: 'action',
        headerClassName: styles.header,
        headerName: 'Action',
        hideSortIcons: true,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',

        renderCell: (params) => {
          const { handleOnClickEdit } = params.row;

          const agency: Agency = {
            agency: params.row.agency,
            name: params.row.name,
            email: params.row.email,
            cell: params.row.cell,
            url: params.row.url,
            logo: params.row.logo,
            theme: {
              theme: params.row.theme.theme,
              properties: { ...params.row.theme.properties },
            },
            _id: params.row._id,
            fbLoginEmails: params.row.fbLoginEmails,
            page: {
              _id: params.row.page?._id,
              name: params.row.page?.name,
              accessToken: params.row.page?.accessToken,
              fbPageId: params.row.page?.fbPageId,
              imageUrl: params.row.page?.imageUrl,
            },
          };

          return (
            <div>
              <IconButton
                onClick={() => handleOnClickEdit(agency)}
                size="small"
              >
                <ModeEditOutlineOutlinedIcon
                  sx={{ fontSize: '18px', color: 'green' }}
                />
              </IconButton>
            </div>
          );
        },
      },
    ];
  }

  return columns;
};
