/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  Chip,
  Switch,
  Autocomplete,
  InputAdornment,
  Checkbox,
} from '@mui/material';
import {
  Brand,
  BrandErrors,
  CannedResponse,
  ChangeEventType,
  Fee,
  InvestmentRequirement,
  InvitationErrors,
  InviteInfo,
  PageFormFields,
  Salesperson,
} from '../../types';
import { PrimaryButton } from '../Buttons';
import CircularLoading from '../CircularLoading';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import {
  ADMIN,
  AGENCY,
  CANNED_RESPONSE_FIELDS,
  IS_DIY_ADZ,
  MARKETING_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import ConnectFacebookPageForm from './ConnectFacebookPageForm';
import { createBrand, editBrand, getSingleBrand } from '../../services/brand';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context';
import { connectFbPage, disconnectPage } from '../../services/page';
import {
  getAllBrandManagersByBrand,
  getAllSalespersonByBrand,
} from '../../services/salesperson';
import { useDispatch, useSelector } from 'react-redux';
import { XsOnly } from '../../utils/breakpoints';
import PageHeader from '../PageHeader';
import AddIcon from '@mui/icons-material/Add';
import BrandUserForm from './BrandUserForm';
import { setLocation, setLocations, toggleAlert } from '../../redux/actions';
import { inviteSalesperson } from '../../services/invitation';
import InviteModal from '../Modal/InviteModal';
import { humanizeString } from '../../utils/stringModifier';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import { FEE_TYPES } from '../../utils/helpers/stripeHelper';
import { createOrUpdateFee, fetchAllFeesByBrand } from '../../services/fee';
import ConfirmNavigationModal, { usePrompt } from '../Navigation/RoutePrompt';
import { buildAccessToken } from '../../utils/helpers/DefaultTokenBuilder';
import DiySettingsForm from './Diy/DiySettingsForm';
import { retriveStripeSubscription } from '../../services/stripe/subscription';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import { DiySettings, DiySettingsError } from '../../types/IDiy';
import {
  createOrUpdateDiySettings,
  getSingleDiySetting,
} from '../../services/diy';
import { AnalyticTools, analyticTools } from '../../utils/helpers/BrandHelpers';

const MAX_USER_COUNT: number = 10;

const LocationForm: React.FC = () => {
  const brand: Brand = useSelector((state: any) => state.brand?.brand);
  const location: Brand = useSelector((state: any) => state.location?.location);
  const locations: Brand[] = useSelector(
    (state: any) => state.locations?.locations,
  );
  const dispatch = useDispatch();
  const pathLocation = useLocation();
  const { state } = useContext(AuthContext);
  const accessToken = state.authUser?.accessToken;
  const role = state.role;
  const isAdmin = role === ADMIN;
  const isAgency = role === AGENCY;
  const isSuperAdmin = role === SUPER_ADMIN;
  const isSalesperson = role === SALESPERSON;
  const isMarketingManager = role === MARKETING_MANAGER;
  const roleBasedId = state.roleBasedId;
  const userBrand = state.authUser?.brand;
  const canConnectPage = isAgency || isSuperAdmin || isAdmin;
  const pathnames = pathLocation.pathname.split('/').filter((x) => x);
  const isFranchisor = userBrand === null || userBrand === undefined;
  const capabilities = state.capabilities;
  const isEdit =
    pathnames.includes('preferencez') ||
    pathnames.includes('edit') ||
    pathnames.includes('preferences');
  const navigate = useNavigate();

  const [selectedForm, setSelectedForm] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultCannedResponse] = useState<CannedResponse[]>([
    {
      message: `Hi <<lead>>! Good day, I'm <<salesperson>> of <<brand>>, how can I help you?`,
      isDeleted: false,
    },
    {
      message: `Hi <<lead>> you want to schedule a call? Here's my Calendly link <<calendly_link>>.`,
      isDeleted: false,
    },
    {
      message:
        'I am glad I was able to help. Please tell me if you have any more queries 🙂',
      isDeleted: false,
    },
  ]);
  const [formValues, setFormValues] = useState<Brand>({
    brand: '',
    allLeads: 'No',
    zipcodereq: 'No',
    financialreq: 'Yes',
    redirect: 'No',
    redirectlink: '',
    redirecttext: '',
    industryRequirement: 'No',
    schedulecall: 'No',
    industryQuestion: '',
    calendly: '',
    salesperson: {
      name: '',
      _id: '',
      email: '',
      role: '',
      user: {
        email: '',
      },
    },
    salespersonTitle: '',
    page: null,
    phoneNumReq: 'No',
    budgetOffset: 100,
    cannedResponses: [
      {
        message: `Hi <<lead>>! Good day, I'm <<salesperson>> of <<brand>>, how can I help you?`,
        isDeleted: false,
      },
      {
        message: `Hi <<lead>> you want to schedule a call? Here's my Calendly link <<calendly_link>>.`,
        isDeleted: false,
      },
      {
        message:
          'I am glad I was able to help. Please tell me if you have any more queries 🙂',
        isDeleted: false,
      },
    ],
    manager: {
      name: '',
      _id: '',
      email: '',
      role: '',
      user: {
        email: '',
      },
    },
    active: true,
    paused: false,
    lastPausedAt: null,
    facebookBusinessIds: [],
    googleCustomerIds: [],
    allowLocations: false,
    agency: {
      _id: brand?.agency?._id || '',
      agency: brand?.agency?.agency || '',
    },
    franchisor: {
      _id: brand?._id,
      brand: brand?.brand,
    },
    __type: 'location',
    allowSetupDepositAccount: false,
    allowSetupPayment: false,
    diy: IS_DIY_ADZ,
    users: [],
    address: {
      line1: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      lat: 0,
      lng: 0,
    },
    analyticTools: [],
    businessType: brand?.businessType,
    industry: brand?.industry,
  });
  const [investmentRequirement, setInvestmentRequirement] =
    useState<InvestmentRequirement>({
      text: '',
      num: 0,
      top: 0,
    });
  const [errors, setErrors] = useState<BrandErrors>({
    brand: '',
    agency: '',
    salesperson: '',
    users: '',
    locationLabel: '',
    businessType: '',
    industry: '',
    lat: '',
    lng: '',
  });
  const [diySettingsErrors, setDiySettingsErrors] = useState<DiySettingsError>({
    defaultUrl: '',
    defaultUser: '',
    //defaultEmploymentUrl: '',
  });
  const [openPageList, setOpenPageList] = useState<boolean>(false);
  const [salespersons, setSalespersons] = useState<Salesperson[]>([]);
  const [managers, setManagers] = useState<Salesperson[]>([]);
  const [showSalespersonForm, setShowSalespersonForm] =
    useState<boolean>(false);
  const [showManagerForm, setShowManagerForm] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [inviteLoading, setInviteLoading] = useState<boolean>(false);
  const [invitationErrors, setInvitationErrors] = useState<InvitationErrors>({
    email: '',
  });
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [invitationInfo, setInvitationInfo] = useState<InviteInfo>({
    name: '',
    email: '',
    id: '',
    role: '',
  });
  const [brandFeesForm, setBrandFeesForm] = useState<Fee[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openConfirmNavModal, setOpenConfirmNavModal] =
    useState<boolean>(false);
  const [autoUnblockingTx, setAutoUnblockingTx] = useState<any>(null);
  const [defaultAccessToken, setDefaultAccessToken] = useState<string>(null);
  const [subscription, setSubscription] = useState<any>(null);
  const [showMultipleUserForms, setShowMultipleUserForms] = useState<boolean[]>(
    [false],
  );
  const [formCount, setFormCount] = useState<number>(1);
  const [diySettingForm, setDiySettingForm] = useState<DiySettings>({
    ref: (location || brand)?._id,
    docModel: 'Brand',
    allowTrafficAds: true,
    allowLeadAds: false,
    allowMessengerAds: false,
    allowAppAds: false,
    allowAllGeolocation: false,
    locationTypes: ['address'],
    excludedLocationTypes: [],
    defaultUrl: '',
    defaultEmploymentUrl: '',
    defaultCalendarUrl: '',
    defaultPrivacyPolicyUrl: '',
    appDeepLink: '',
    maxCityRadius: 50,
    maxAddressRadius: 50,
    allowSetupOwnAccount: formValues?.__type === 'brand',
    geolocation: null,
    facebookPage: null,
    instagramAccount: null,
    fbAdAccount: null,
    defaultLocation: null,
    defaultUser: null,
    excludedGeolocations: null,
    excludeGeolocation: false,
    targetCountries: [{ code: 'US', label: 'United States', phone: '1' }],
  });
  const [diySettingLoading, setDiySettingLoading] = useState<boolean>(false);

  usePrompt(setOpenConfirmNavModal, setAutoUnblockingTx, isDirty);

  useEffect(() => {
    handleBuildProviderTokens();
  }, []);

  useEffect(() => {
    if (brand) {
      allAgenciesAndSalesperson();
    }
  }, [brand]);

  useEffect(() => {
    if (!isEdit && brand) {
      resetForm();
      resetDiyForm();
      getBrandDiySettings(brand);
    }
  }, [isEdit, brand]);

  useEffect(() => {
    if (isEdit && location?._id) {
      getBrand(location?._id);
      getBrandFees(location, 'brand');
      getSubscription(location);
    }
  }, [location, isEdit]);

  useEffect(() => {
    if (!isEdit && formValues?.allowSetupPayment && brand) {
      getBrandFees(brand, 'location');
    }
  }, [formValues.allowSetupPayment, isEdit, brand]);

  const LOCATION_LABEL: string =
    (location || brand)?.franchisor?.locationLabel || 'location';

  const getBrand = async (brandId: string) => {
    try {
      setFetchLoading(true);

      const response: Brand = await getSingleBrand(brandId);

      const diySettings = await getSingleDiySetting(brandId);
      setFormCount(response?.users?.length || 1);

      let temp: Brand = {
        ...response,
        cannedResponses: response?.cannedResponses || defaultCannedResponse,
      };

      if (diySettings.data.ref !== brandId) {
        temp = {
          ...temp,
          facebookBusinessIds:
            (response?.__type === 'location' ? response?.franchisor : response)
              ?.facebookBusinessIds || [],
        };

        const {
          _id,
          ref,
          docModel,
          locationTypes,
          geolocation,
          maxCityRadius,
          maxAddressRadius,
          defaultUser,
          allowSetupOwnAccount,
          ...rest
        } = diySettings.data;

        setDiySettingForm({
          ...rest,
          ref: response._id,
          docModel: 'Brand',
          locationTypes: ['address'],
          maxCityRadius: 50,
          maxAddressRadius: 50,
        });
      } else {
        setDiySettingForm({
          ...diySettings.data,
          ref: response._id,
          docModel: 'Brand',
        });
      }

      setInvestmentRequirement(response.investmentRequirement);

      setFormValues(temp);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getBrandDiySettings = async (brand: Brand) => {
    setDiySettingLoading(true);
    try {
      const response = await getSingleDiySetting(brand?._id);

      const {
        _id,
        ref,
        docModel,
        locationTypes,
        geolocation,
        maxCityRadius,
        maxAddressRadius,
        defaultUser,
        allowSetupOwnAccount,
        ...rest
      } = response.data;

      setDiySettingForm({
        ...rest,
        maxCityRadius: 50,
        maxAddressRadius: 50,
      });
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setDiySettingLoading(false);
    }
  };

  const getBrandFees = async (location: Brand, entity?: string) => {
    try {
      setLoading(true);

      const response = await fetchAllFeesByBrand(location?._id, entity);

      buildBrandFeesForm('brand', response.data || [], location);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getSubscription = async (brand: Brand) => {
    try {
      const response = await retriveStripeSubscription(brand?._id);

      setSubscription(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const allAgenciesAndSalesperson = async () => {
    try {
      setFetchLoading(true);

      const brandSalespersonResponse = await getAllSalespersonByBrand(
        brand?._id,
        1,
        1000,
        'active',
        undefined,
        undefined,
        undefined,
        IS_DIY_ADZ,
      );
      const brandSalespersons = brandSalespersonResponse.data.map(
        (salesperson: Salesperson) => {
          return {
            _id: salesperson._id,
            name: salesperson.name,
            salespersonId: salesperson.salespersonId,
            email: salesperson.email,
            role: salesperson.role,
            user: salesperson.user,
          };
        },
      );

      const brandManagersResponse = await getAllBrandManagersByBrand(
        brand?._id,
        1,
        1000,
        'active',
        IS_DIY_ADZ,
      );

      const brandManagers = brandManagersResponse.data.map(
        (salesperson: Salesperson) => {
          return {
            _id: salesperson._id,
            name: salesperson.name,
            salespersonId: salesperson.salespersonId,
            email: salesperson.email,
            role: salesperson.role,
            user: salesperson.user,
          };
        },
      );

      setSalespersons(brandSalespersons);
      setManagers(brandManagers);
    } catch (error: any) {
      console.log(error.message);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
    } finally {
      setFetchLoading(false);
    }
  };

  const handleBuildProviderTokens = () => {
    buildAccessToken(accessToken, setDefaultAccessToken);
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);
      if (IS_DIY_ADZ) {
        if (!diySettingForm?.defaultUrl) {
          setLoading(false);
          return setDiySettingsErrors({
            defaultUrl: 'Default website URL is required',
          });
        }

        /**
         * TODO: Re-enable if we required default employment URL
        if (!diySettingForm?.defaultEmploymentUrl) {
          setLoading(false);
          return setDiySettingsErrors({
            defaultUrl: 'Default employment URL is required',
          });
        }
        */

        if (
          diySettingForm?.allowSetupOwnAccount &&
          !diySettingForm?.defaultUser
        ) {
          return setDiySettingsErrors({
            defaultUser: 'Default user is required',
          });
        }
      }

      setIsDirty(false);

      let locationFeePayload: Fee[] = [];
      let obj: Brand = {
        investmentRequirement,
        __type: 'location',
      };

      Object.keys(formValues).forEach((key) => {
        if (formValues[key as keyof Brand] !== '') {
          obj = { ...obj, [key]: formValues[key as keyof Brand] };
        }
      });

      if (isEdit) {
        const response = await editBrand(
          {
            ...obj,
            cannedResponses: buildCannedResponses(),
            businessType: obj?.franchisor?.businessType,
            industry: obj?.franchisor?.industry,
          },
          location?._id,
        );

        let temp: Brand[] = [];
        locations.forEach((location: Brand) => {
          if (location?._id === response.updatedBrand._id) {
            location = response.updatedBrand;
          }

          temp = [...temp, location];
        });
        dispatch(setLocation(response.updatedBrand));
        dispatch(setLocations(temp));

        brandFeesForm.forEach((fee: Fee) => {
          fee.ref = response.updatedBrand._id;
          locationFeePayload = [...locationFeePayload, fee];
        });

        if (IS_DIY_ADZ) setupDiySetting(response.updatedBrand._id);

        dispatch(
          toggleAlert({
            toggle: true,
            message: `${humanizeString(LOCATION_LABEL)} updated successfully`,
          }),
        );
      } else {
        const { brand } = await createBrand({
          ...obj,
          cannedResponses: buildCannedResponses(),
        });

        let temp: Brand[] = [...locations, brand];
        dispatch(setLocation(brand));
        dispatch(setLocations(temp));

        brandFeesForm.forEach((fee: Fee) => {
          fee.ref = brand._id;
          locationFeePayload = [...locationFeePayload, fee];
        });

        if (IS_DIY_ADZ) setupDiySetting(brand._id);

        dispatch(
          toggleAlert({
            toggle: true,
            message: `${humanizeString(LOCATION_LABEL)} created successfully`,
          }),
        );

        if (isSuperAdmin || isAdmin) {
          handleCreateOrUpdateFees('brand', locationFeePayload);
        }

        const redirectUrl = IS_DIY_ADZ
          ? brand?.analyticTools.includes(AnalyticTools.SCORECARDZ)
            ? '/scorecardz'
            : '/trendz'
          : '/dashboard';

        navigate(redirectUrl);
      }

      setDiySettingsErrors({
        defaultUrl: '',
        defaultUser: '',
      });
    } catch (error: any) {
      setIsDirty(true);
      const err = error?.response?.data;
      console.log(err);
      console.log(error.message);
      setErrors({
        brand: err?.brand,
        agency: err?.agency,
        salesperson: err?.salesperson,
        users: err?.users,
        locationLabel: err?.locationLabel,
        businessType: err?.businessType,
        industry: err?.industry,
        lat: err?.lat,
        lng: err?.lng,
      });
    } finally {
      setLoading(false);
    }
  };

  const setupDiySetting = async (brandId: string) => {
    try {
      const response = await createOrUpdateDiySettings(brandId, diySettingForm);

      setDiySettingForm(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleInviteSalesperson = async (email: string) => {
    try {
      setInviteLoading(true);
      const response = await inviteSalesperson({
        email,
        clientUri: window.location.host,
        diy: IS_DIY_ADZ,
      });
      setOpenInviteModal(false);

      let users: any[] = [];
      formValues?.users.forEach((user: any) => {
        if (user.email === email) {
          user.invitedAt = response?.data?.invitedAt;
        }

        users = [...users, user];
      });

      setFormValues({ ...formValues, users });

      dispatch(
        toggleAlert({
          toggle: true,
          message: response?.message,
        }),
      );
    } catch (error: any) {
      const err = error.response.data;

      setInvitationErrors({
        ...invitationErrors,
        email: err.email,
      });
    } finally {
      setInviteLoading(false);
    }
  };

  const handleCreateOrUpdateFees = async (entity: string, payload: Fee[]) => {
    try {
      const response = await createOrUpdateFee(payload);

      setBrandFeesForm(response?.data || []);
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    }
  };

  const handleConnectPage = async (
    page: any,
    objectId: string,
    model: string,
  ) => {
    try {
      setLoading(true);
      setIsDirty(true);

      const formObj: PageFormFields = {
        accessToken: page?.accessToken,
        name: page?.name,
        fbPageId: page?.fbPageId,
        imageUrl: page?.imageUrl,
        objectId,
        model,
      };
      const data = await connectFbPage(formObj);
      if (data) {
        const response = {
          _id: data.page?._id,
          accessToken: data.page?.accessToken,
          name: data.page?.name,
          fbPageId: data.page?.fbPageId,
          imageUrl: data.page?.imageUrl,
        };
        setFormValues({
          ...formValues,
          page: response,
        });

        handleClosePageListModal();
        dispatch(
          toggleAlert({
            toggle: true,
            message: data.message,
          }),
        );
      }
    } catch (error: any) {
      const err = error.response.data;
      dispatch(
        toggleAlert({
          toggle: true,
          message: err?.message,
          type: 'error',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnectPage = async (
    page: any,
    objectId: string,
    model: string,
  ) => {
    try {
      setLoading(true);
      setIsDirty(true);

      const formObj: PageFormFields = {
        accessToken: page?.accessToken,
        name: page?.name,
        fbPageId: page?.fbPageId,
        imageUrl: page?.imageUrl,
        objectId,
        model,
      };

      const data = await disconnectPage(formObj);
      if (data) {
        setFormValues({ ...formValues, page: null });
        dispatch(
          toggleAlert({
            toggle: true,
            message: data.message,
          }),
        );
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddResponses = () => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    newFormObj.cannedResponses = [
      ...formValues.cannedResponses,
      { message: '', isDeleted: false },
    ];

    setFormValues(newFormObj);
  };

  const handleDeleteReponses = (index: number) => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    newFormObj.cannedResponses[index].isDeleted = true;

    setFormValues(newFormObj);
  };

  const handleEditResponses = (index: number, value: string) => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    newFormObj.cannedResponses[index].message = value;

    setFormValues(newFormObj);
  };

  const handleSetCustomFields = (
    selectedForm: number,
    customFields: string,
  ) => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    const message = newFormObj.cannedResponses[selectedForm].message;
    newFormObj.cannedResponses[
      selectedForm
    ].message = `${message} ${customFields}`;

    setFormValues(newFormObj);
  };

  const handleAllowAdBudget = async (
    feeType: string,
    allowed: boolean,
    entity: string,
  ) => {
    try {
      setIsDirty(true);
      const temp = [...brandFeesForm];

      const fee: Fee = temp.find((fee: Fee) => {
        return fee.type === feeType;
      });

      fee.enabled = allowed;

      setBrandFeesForm(temp);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleOnChangeFees = (
    e: ChangeEventType,
    index: number,
    entity: string,
  ) => {
    setIsDirty(true);
    const temp = [...brandFeesForm];
    temp[index].amount = parseFloat(e.target.value);

    setBrandFeesForm(temp);
  };

  const buildCannedResponses = () => {
    let newFormObj = { ...formValues };
    let newCannedResponses: CannedResponse[] = [];
    newFormObj.cannedResponses.forEach((response: CannedResponse) => {
      if (!response.isDeleted) {
        newCannedResponses = [...newCannedResponses, response];
      } else {
        return;
      }
    });

    return newCannedResponses;
  };

  const buildBrandFeesForm = (
    entity: string,
    response: Fee[],
    brand?: Brand,
  ) => {
    let payloads: Fee[] = [];

    FEE_TYPES.forEach((feeType: string, index: number) => {
      const fee = response.find((data: Fee) => {
        return data.type === feeType;
      });

      if (fee) {
        const params: Fee = {
          name: fee.name,
          ref: brand?._id,
          docModel: 'Brand',
          oneTime: fee.oneTime,
          type: fee.type,
          amount: (fee?.amount || 0) === 0 ? 0 : fee?.amount || 0,
          provider: 'stripe',
          enabled: true,
          entity,
          position: index + 1,
        };
        payloads = [...payloads, params];
      } else {
        const payload: Fee = {
          name: feeType === 'ad-budget' ? 'Ad Budget' : humanizeString(feeType),
          ref: brand?._id,
          docModel: 'Brand',
          oneTime: feeType === 'setup',
          type: feeType,
          amount: (fee?.amount || 0) === 0 ? 0 : fee?.amount || 0,
          provider: 'stripe',
          enabled: true,
          entity,
          position: index + 1,
        };

        payloads = [...payloads, payload];
      }
    });

    if (entity === 'brand') {
      setBrandFeesForm(payloads);
    }
  };

  const handleOpenPageListModal = () => {
    setOpenPageList(true);
  };

  const handleClosePageListModal = () => {
    setOpenPageList(false);
  };

  const handleOpenSalespersonForm = () => {
    setShowSalespersonForm(true);
  };

  const handleCloseSalespersonForm = () => {
    setShowSalespersonForm(false);
  };

  const handleOpenManagerForm = () => {
    setShowManagerForm(true);
  };

  const handleCloseManagerForm = () => {
    setShowManagerForm(false);
  };

  const handleOpenInviteModal = (info: InviteInfo) => {
    setOpenInviteModal(true);
    setInvitationInfo({
      ...invitationInfo,
      name: info.name,
      email: info.email,
      id: info.id,
      role: info.role,
    });
  };

  const handleCloseInviteModal = () => {
    setInvitationErrors({
      ...invitationErrors,
      email: '',
    });
    setOpenInviteModal(false);
  };

  const resetDiyForm = () => {
    setDiySettingForm({
      ref: brand?._id,
      docModel: 'Brand',
      allowTrafficAds: true,
      allowLeadAds: false,
      allowMessengerAds: false,
      allowAppAds: false,
      allowAllGeolocation: false,
      locationTypes: ['address'],
      defaultUrl: '',
      defaultEmploymentUrl: '',
      defaultCalendarUrl: '',
      defaultPrivacyPolicyUrl: '',
      appDeepLink: '',
      maxCityRadius: 50,
      maxAddressRadius: 50,
      allowSetupOwnAccount: formValues?.__type === 'brand',
      geolocation: null,
      facebookPage: null,
      instagramAccount: null,
      fbAdAccount: null,
      defaultLocation: null,
      defaultUser: null,
      excludedGeolocations: null,
      excludedLocationTypes: [],
      excludeGeolocation: false,
      targetCountries: [{ code: 'US', label: 'United States', phone: '1' }],
    });
  };

  const resetForm = () => {
    setFormValues({
      brand: '',
      allLeads: 'No',
      zipcodereq: 'No',
      financialreq: 'Yes',
      redirect: 'No',
      redirectlink: '',
      redirecttext: '',
      industryRequirement: 'No',
      schedulecall: 'No',
      industryQuestion: '',
      calendly: '',
      salesperson: {
        name: '',
        _id: '',
        email: '',
        role: '',
        user: {
          email: '',
        },
      },
      salespersonTitle: '',
      page: null,
      phoneNumReq: 'No',
      budgetOffset: !isEdit ? brand?.budgetOffset : 100,
      budgetPercentageFee: 18,
      cannedResponses: [
        {
          message: `Hi <<lead>>! Good day, I'm <<salesperson>> of <<brand>>, how can I help you?`,
          isDeleted: false,
        },
        {
          message: `Hi <<lead>> you want to schedule a call? Here's my Calendly link <<calendly_link>>.`,
          isDeleted: false,
        },
        {
          message:
            'I am glad I was able to help. Please tell me if you have any more queries 🙂',
          isDeleted: false,
        },
      ],
      manager: {
        name: '',
        _id: '',
        email: '',
        role: '',
        user: {
          email: '',
        },
      },
      active: true,
      paused: false,
      lastPausedAt: null,
      facebookBusinessIds: !isEdit ? brand?.facebookBusinessIds || [] : [],
      googleCustomerIds: [],
      allowLocations: false,
      agency: {
        _id: brand?.agency?._id || '',
        agency: brand?.agency?.agency || '',
      },
      franchisor: {
        _id: brand?._id,
        brand: brand?.brand,
      },
      __type: 'location',
      allowSetupDepositAccount: false,
      allowSetupPayment: !isEdit ? brand?.allowLocationsSetupPayment : false,
      address: {
        line1: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        lat: null,
        lng: null,
      },
      analyticTools: !isEdit ? brand?.analyticTools || [] : [],
      industry: '',
      businessType: '',
      diy: IS_DIY_ADZ,
      phoneNumber: '',
      users: [],
    });
  };

  const handleConfirmNavigation = () => {
    autoUnblockingTx.retry();
    setOpenConfirmNavModal(false);
  };

  const handleCancelNavigation = () => {
    setOpenConfirmNavModal(false);
  };

  const handleOpenUserForm = (index: number) => {
    let userForms: boolean[] = [...showMultipleUserForms];

    userForms[index] = true;

    setShowMultipleUserForms(userForms);
  };

  const handleOnCloseMultipleUserForm = (index: number) => {
    let userForms: boolean[] = [...showMultipleUserForms];

    userForms[index] = false;

    setShowMultipleUserForms(userForms);
  };

  const handleRemoveUser = (index: number) => {
    let showForms: boolean[] = [...showMultipleUserForms];
    let users: any[] = [...formValues?.users];
    if (users[index]) {
      if (users[index]?._id === diySettingForm?.defaultUser?._id) {
        setDiySettingForm({ ...diySettingForm, defaultUser: null });
      }

      users.splice(index, 1);
      setFormValues({ ...formValues, users });
    }

    showForms.splice(index, 1);
    setFormCount((prev) => prev - 1);
  };

  const handleAddUser = () => {
    setShowMultipleUserForms([...showMultipleUserForms, false]);
    setFormCount((prev) => prev + 1);
  };

  const multipleUserForm = () => {
    let components: any[] = [];

    for (let i = 0; i < formCount; i++) {
      const disabled = (formValues?.users || [])[i]?._id === roleBasedId;

      components = [
        ...components,
        <>
          <Grid item key={i + 1} xs={12}>
            <p className={styles.label}>{`User #${i + 1}`}</p>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={disabled}
                  id={`user-${i + 1}`}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option._id}>
                        {`${option.salespersonId}. ${option.name}`}
                      </li>
                    );
                  }}
                  options={salespersons.sort(
                    (a, b) => -b.name?.localeCompare(a.name),
                  )}
                  value={
                    formValues?.users?.length > 0 ? formValues?.users[i] : null
                  }
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Name"
                      placeholder="Select a user"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      error={errors?.users && i === 0 ? true : false}
                      helperText={errors?.users && i === 0 ? errors?.users : ''}
                    />
                  )}
                  disableClearable
                  onChange={async (e: any, value: any) => {
                    setIsDirty(true);
                    let users: any[] = [...(formValues?.users || [])];
                    if (users.length === 0) {
                      users = [...users, value];
                    } else {
                      users[i] = value;
                    }

                    setFormValues({ ...formValues, users });
                  }}
                  getOptionDisabled={(option) => {
                    const ids = formValues?.users?.map((user: any) => {
                      return user._id;
                    });
                    return ids?.includes(option._id);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  justifyContent: 'left',
                  alignItems: 'left',
                  display: 'flex',
                }}
              >
                {!showMultipleUserForms[i] ? (
                  <PrimaryButton
                    title={`${
                      (formValues?.users || [])[i] ? 'Edit' : 'Create'
                    } User`}
                    type="button"
                    variant="text"
                    startIcon={<PersonAddAltOutlinedIcon />}
                    fontSize="12px"
                    handleOnClick={() => {
                      setIsDirty(true);
                      handleOpenUserForm(i);
                    }}
                    disabled={disabled}
                  />
                ) : null}

                <PrimaryButton
                  title="Remove User"
                  type="button"
                  variant="text"
                  startIcon={<GroupRemoveOutlinedIcon />}
                  fontSize="12px"
                  handleOnClick={() => {
                    setIsDirty(true);
                    handleRemoveUser(i);
                  }}
                  disabled={disabled}
                />

                {!(formValues.users || [])[i]?._id ||
                (formValues.users || [])[i]?.user?.email ? (
                  <PrimaryButton
                    title="Invite Accepted"
                    type="button"
                    variant="text"
                    startIcon={<ContactMailOutlinedIcon />}
                    fontSize="12px"
                    disabled
                  />
                ) : (
                  <PrimaryButton
                    title={
                      (formValues.users || [])[i]?.invitedAt
                        ? 'Resend Invite'
                        : 'Invite User'
                    }
                    type="button"
                    variant="text"
                    startIcon={<ContactMailOutlinedIcon />}
                    fontSize="12px"
                    handleOnClick={() => {
                      handleOpenInviteModal({
                        ...formValues?.users[i],
                      } as InviteInfo);
                    }}
                    disabled={disabled}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {showMultipleUserForms[i] ? (
            <Grid item xs={12}>
              <BrandUserForm
                isLocation={true}
                salespersons={salespersons}
                setSalespersons={setSalespersons}
                brandFormValues={formValues}
                setBrandFormValues={setFormValues}
                onCloseManagerForm={handleCloseManagerForm}
                onCloseSalespersonForm={handleCloseSalespersonForm}
                onCloseMultipleForm={handleOnCloseMultipleUserForm}
                role="salesperson"
                multiple
                index={i}
                edit={(formValues?.users || [])[i] ? true : false}
                salespersonId={
                  (formValues?.users || [])[i]
                    ? (formValues?.users || [])[i]._id
                    : null
                }
              />
            </Grid>
          ) : null}
        </>,
      ];
    }
    return components;
  };

  return (
    <Box
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
      justifyContent="center"
    >
      <CircularLoading loading={fetchLoading} />

      <PageHeader
        title={
          isEdit
            ? `Edit ${humanizeString(LOCATION_LABEL)}`
            : `Add New ${humanizeString(brand?.locationLabel || 'location')}`
        }
      />

      {/** LOCATION DETAILS SECTION */}
      {isAdmin ||
      isSuperAdmin ||
      isFranchisor ||
      isMarketingManager ||
      isSalesperson ||
      capabilities?.addLocations ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                variant="standard"
                type="text"
                id="brand"
                name="brand"
                label={humanizeString(LOCATION_LABEL)}
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({ ...formValues, brand: e.target.value });
                }}
                value={formValues.brand}
                InputLabelProps={{ shrink: true }}
                size="small"
                autoFocus
                error={errors.brand ? true : false}
                helperText={errors?.brand ? errors?.brand : ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="standard"
                required
                type="text"
                id="franchisor"
                name="franchisor"
                label="Franchisor"
                value={isEdit ? formValues.franchisor?.brand : brand?.brand}
                InputLabelProps={{ shrink: true }}
                size="small"
                autoFocus
                disabled
              />
            </Grid>
          </Grid>

          {!IS_DIY_ADZ ? (
            <>
              <p className={styles.label}>Salesperson:</p>

              <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={7}>
                      <Autocomplete
                        id="salesperson"
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option._id}>
                              {`${option.salespersonId}. ${option.name}`}
                            </li>
                          );
                        }}
                        options={salespersons.sort(
                          (a, b) => -b.name?.localeCompare(a.name),
                        )}
                        value={formValues?.salesperson}
                        getOptionLabel={(option) => `${option.name}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Name"
                            fullWidth
                            size="small"
                            placeholder="Select a salesperson"
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors?.salesperson ? true : false}
                            helperText={
                              errors?.salesperson ? errors?.salesperson : ''
                            }
                          />
                        )}
                        disableClearable
                        onChange={async (e: any, value: any) => {
                          setIsDirty(true);
                          setFormValues({
                            ...formValues,
                            salesperson: { ...value },
                          });
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={5}
                      sx={{
                        justifyContent: 'left',
                        alignItems: 'left',
                        display: 'flex',
                      }}
                    >
                      {!showSalespersonForm ? (
                        <PrimaryButton
                          title="Add User"
                          type="button"
                          startIcon={<AddIcon />}
                          fontSize="12px"
                          handleOnClick={handleOpenSalespersonForm}
                        />
                      ) : null}

                      {!formValues.salesperson?._id ||
                      formValues.salesperson?.user?.email ? (
                        <PrimaryButton
                          title="Invite Accepted"
                          type="button"
                          variant="text"
                          startIcon={<ContactMailOutlinedIcon />}
                          fontSize="12px"
                          disabled
                        />
                      ) : (
                        <PrimaryButton
                          title={
                            formValues?.salesperson?.invitedAt
                              ? 'Resend Invite'
                              : 'Invite User'
                          }
                          type="button"
                          variant="text"
                          startIcon={<ContactMailOutlinedIcon />}
                          fontSize="12px"
                          handleOnClick={() => {
                            handleOpenInviteModal({
                              ...formValues?.salesperson,
                            } as InviteInfo);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    id="salespersonTitle"
                    name="salespersonTitle"
                    label="Title"
                    onChange={(e: ChangeEventType) => {
                      setIsDirty(true);
                      setFormValues({
                        ...formValues,
                        salespersonTitle: e.target.value,
                      });
                    }}
                    value={formValues.salespersonTitle}
                    placeholder="Sales Manager"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
              </Grid>
            </>
          ) : null}

          {showSalespersonForm ? (
            <Grid container spacing={2} mb={1}>
              <Grid item xs={12}>
                <BrandUserForm
                  isLocation={true}
                  salespersons={salespersons}
                  setSalespersons={setSalespersons}
                  managers={managers}
                  setManagers={setManagers}
                  brandFormValues={formValues}
                  setBrandFormValues={setFormValues}
                  role="salesperson"
                  onCloseSalespersonForm={handleCloseSalespersonForm}
                  onCloseManagerForm={handleCloseManagerForm}
                />
              </Grid>
            </Grid>
          ) : null}

          {!IS_DIY_ADZ ? (
            <>
              <p className={styles.label}>Manager:</p>

              <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={7}>
                      <Autocomplete
                        id="manager"
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option._id}>
                              {`${option.salespersonId}. ${option.name}`}
                            </li>
                          );
                        }}
                        options={managers.sort(
                          (a, b) => -b.name?.localeCompare(a.name),
                        )}
                        value={formValues?.manager}
                        getOptionLabel={(option) => `${option.name}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Name"
                            placeholder="Select a manager"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            size="small"
                          />
                        )}
                        disableClearable
                        onChange={async (e: any, value: any) => {
                          setIsDirty(true);
                          setFormValues({
                            ...formValues,
                            manager: { ...value },
                          });
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={5}
                      sx={{
                        justifyContent: 'left',
                        alignItems: 'left',
                        display: 'flex',
                      }}
                    >
                      {!showManagerForm ? (
                        <PrimaryButton
                          title="Add User"
                          type="button"
                          startIcon={<AddIcon />}
                          fontSize="12px"
                          handleOnClick={handleOpenManagerForm}
                        />
                      ) : null}

                      {!formValues.manager?._id ||
                      formValues.manager?.user?.email ? (
                        <PrimaryButton
                          title="Invite Accepted"
                          type="button"
                          variant="text"
                          startIcon={<ContactMailOutlinedIcon />}
                          fontSize="12px"
                          disabled
                        />
                      ) : (
                        <PrimaryButton
                          title={
                            formValues?.manager?.invitedAt
                              ? 'Resend Invite'
                              : 'Invite User'
                          }
                          type="button"
                          variant="text"
                          startIcon={<ContactMailOutlinedIcon />}
                          fontSize="12px"
                          handleOnClick={() => {
                            handleOpenInviteModal({
                              ...formValues?.manager,
                            } as InviteInfo);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}

          {showManagerForm ? (
            <Grid container spacing={2} mb={1}>
              <Grid item xs={12}>
                <BrandUserForm
                  isLocation={true}
                  salespersons={salespersons}
                  setSalespersons={setSalespersons}
                  managers={managers}
                  setManagers={setManagers}
                  brandFormValues={formValues}
                  setBrandFormValues={setFormValues}
                  onCloseManagerForm={handleCloseManagerForm}
                  onCloseSalespersonForm={handleCloseSalespersonForm}
                  role="brand-manager"
                />
              </Grid>
            </Grid>
          ) : null}

          {IS_DIY_ADZ &&
          (isSuperAdmin ||
            isAdmin ||
            (!(isSuperAdmin || isAdmin) && capabilities?.addUser)) ? (
            <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
              {multipleUserForm()}

              <Grid item xs={12}>
                {formCount !== MAX_USER_COUNT ? (
                  <PrimaryButton
                    title="Add User"
                    type="button"
                    handleOnClick={handleAddUser}
                    startIcon={<AddIcon />}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : null}

          {isSuperAdmin || isAdmin ? (
            <div>
              <p className={styles.label}>Campaign Setup</p>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="number"
                    id="budgetOffset"
                    name="budgetOffset"
                    label="Budget Offset"
                    onChange={(e: ChangeEventType) => {
                      setIsDirty(true);
                      setFormValues({
                        ...formValues,
                        budgetOffset: parseFloat(e.target.value),
                      });
                    }}
                    value={formValues.budgetOffset}
                    placeholder="0"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          ) : null}

          <Grid container spacing={2} mb={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="status">{`${humanizeString(
                  LOCATION_LABEL,
                )} Status`}</FormLabel>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.active}
                      name="active"
                      onChange={(e: any) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={formValues.active ? 'Active' : 'Inactive'}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="status">Campaign Status</FormLabel>

                <FormControlLabel
                  control={
                    <Switch
                      checked={!formValues.paused}
                      name="paused"
                      onChange={(e: any) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: !e.target.checked,
                        });
                      }}
                    />
                  }
                  label={!formValues.paused ? 'Running' : 'Paused'}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/** Add isFranchisor is were allowing franchisor to set Location credit card setup */}
          {isSuperAdmin || isAdmin ? (
            <>
              <Grid item xs={12} mt={2} mb={2}>
                <Divider />
              </Grid>

              <p className={styles.label}>Payment Setup</p>

              <Grid container spacing={2} mb={1}>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel id="status">Allow Credit Card Setup</FormLabel>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.allowSetupPayment}
                          name="allowSetupPayment"
                          onChange={(e: any) => {
                            setIsDirty(true);
                            setFormValues({
                              ...formValues,
                              [e.target.name]: e.target.checked,
                            });
                          }}
                        />
                      }
                      label={
                        formValues.allowSetupPayment ? 'Enabled' : 'Disabled'
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : null}

          {formValues.allowSetupPayment &&
          (isSuperAdmin || isAdmin) &&
          IS_DIY_ADZ ? (
            <Grid container spacing={2} mt={1} mb={1}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  type="number"
                  label={`Subscription Fee Percentage`}
                  onChange={(e: ChangeEventType) => {
                    setIsDirty(true);
                    setFormValues({
                      ...formValues,
                      budgetPercentageFee: parseFloat(e.target.value),
                    });
                  }}
                  value={formValues?.budgetPercentageFee}
                  placeholder="18"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  sx={{
                    marginRight: '10px',
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                  }}
                />
              </Grid>
            </Grid>
          ) : null}

          {isAdmin || isSuperAdmin ? (
            <>
              <Grid item xs={12} mb={1}>
                <Divider />
              </Grid>

              <Grid item xs={12} mb={1}>
                <p className={styles.label}>Analytic Tools</p>
              </Grid>

              {!fetchLoading ? (
                <Grid item xs={12} mb={1}>
                  {analyticTools.map((tool: string, index: number) => {
                    return (
                      <FormControlLabel
                        key={`analytic-tools-${index}`}
                        control={
                          <Checkbox
                            size="small"
                            defaultChecked={formValues?.analyticTools?.includes(
                              tool,
                            )}
                          />
                        }
                        label={humanizeString(tool)}
                        value={formValues?.analyticTools?.includes(tool)}
                        onChange={(e: any) => {
                          setIsDirty(true);
                          let temp: string[] = [];
                          if (e.target.checked) {
                            temp = [...formValues?.analyticTools, tool];
                          } else {
                            formValues?.analyticTools?.forEach(
                              (analyticTool: string) => {
                                if (analyticTool !== tool) {
                                  temp = [...temp, analyticTool];
                                }
                              },
                            );
                          }

                          setFormValues({
                            ...formValues,
                            analyticTools: temp,
                          });
                        }}
                      />
                    );
                  })}
                </Grid>
              ) : null}
            </>
          ) : null}

          {/** DIY SETTINGS */}
          {IS_DIY_ADZ ? (
            <>
              <Grid item xs={12} mb={1}>
                <Divider />
              </Grid>

              <DiySettingsForm
                formValues={formValues}
                setFormValues={setFormValues}
                accessToken={defaultAccessToken}
                isFranchisee={formValues?.__type === 'location'}
                isAdmin={isAdmin || isSuperAdmin}
                isMarketingManager={isMarketingManager}
                brand={brand}
                setLoading={setFetchLoading}
                userId={roleBasedId}
                role={role}
                diySettings={diySettingForm}
                setDiySettings={setDiySettingForm}
                loading={fetchLoading}
                isEdit={isEdit}
                setIsDirty={setIsDirty}
                errors={errors}
                diySettingsErrors={diySettingsErrors}
              />
            </>
          ) : null}

          <Grid item xs={12} mt={2}>
            <Divider />
          </Grid>

          {!IS_DIY_ADZ ? (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                {defaultAccessToken && canConnectPage ? (
                  <ConnectFacebookPageForm
                    page={formValues.page}
                    objectId={location?._id}
                    model="Brand"
                    handleConnect={handleConnectPage}
                    handleDisconnect={handleDisconnectPage}
                    loading={loading}
                    open={openPageList}
                    handleOpenPageList={handleOpenPageListModal}
                    handleClosePageList={handleClosePageListModal}
                    accessToken={defaultAccessToken}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : null}

          {formValues?.page?._id ? (
            <>
              <p className={styles.label}>Investment Requirement</p>

              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    id="text"
                    name="text"
                    label="Text"
                    onChange={(e: ChangeEventType) => {
                      setIsDirty(true);
                      setInvestmentRequirement({
                        ...investmentRequirement,
                        text: e.target.value,
                      });
                    }}
                    value={investmentRequirement.text}
                    placeholder="$80k"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="number"
                    id="num"
                    name="num"
                    label="Num"
                    onChange={(e: ChangeEventType) => {
                      setIsDirty(true);
                      setInvestmentRequirement({
                        ...investmentRequirement,
                        num: Number(e.target.value),
                      });
                    }}
                    value={investmentRequirement.num}
                    placeholder="80"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="number"
                    id="top"
                    name="top"
                    label="Top"
                    onChange={(e: ChangeEventType) => {
                      setIsDirty(true);
                      setInvestmentRequirement({
                        ...investmentRequirement,
                        top: Number(e.target.value),
                      });
                    }}
                    value={investmentRequirement.top}
                    placeholder="81"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={2} sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="allLeads">All Leads</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="allLeads"
                      name="allLeads"
                      value={formValues.allLeads}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2} sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="zipcodereq">Zip code req</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="zipcodereq"
                      name="zipcodereq"
                      value={formValues.zipcodereq}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2} sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="financialreq">Financial req</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="financialreq"
                      name="financialreq"
                      value={formValues.financialreq}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="Yes"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2} mt={1}>
                  <FormControl>
                    <FormLabel id="phoneNumReq">Phone Num Req</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="phoneNumReq"
                      name="phoneNumReq"
                      value={formValues.phoneNumReq}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={4}>
                  <FormControl>
                    <FormLabel id="redirect">Redirect</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="redirect"
                      name="redirect"
                      value={formValues.redirect}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {formValues.redirect === 'Yes' ? (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="redirectLink"
                      name="redirectLink"
                      label="Redirect Link"
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          redirectlink: e.target.value,
                        });
                      }}
                      value={formValues.redirectlink}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="redirectText"
                      name="redirectText"
                      label="Redirect Text"
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          redirecttext: e.target.value,
                        });
                      }}
                      value={formValues.redirecttext}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={2} mb={1}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="industryRequirement">
                      Industry Required
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="industryRequirement"
                      name="industryRequirement"
                      value={formValues.industryRequirement}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {formValues.industryRequirement === 'Yes' ? (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="industryQuestion"
                      name="industryQuestion"
                      label="Industry Ques"
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          industryQuestion: e.target.value,
                        });
                      }}
                      value={formValues.industryQuestion}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={2} mb={1}>
                <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="schedulecall">Schedule call</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="schedulecall"
                      name="schedulecall"
                      value={formValues.schedulecall}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="Yes"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {formValues.schedulecall === 'Yes' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      id="calendly"
                      name="calendly"
                      label="Calendly"
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          calendly: e.target.value,
                        });
                      }}
                      value={formValues.calendly}
                      placeholder=""
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Divider />
            </>
          ) : null}
        </>
      ) : null}

      {/** CANNED RESPONSES SECTION */}

      {formValues?.page?._id ? (
        <>
          <p className={styles.label}>Canned Responses</p>

          <Stack direction="row" spacing={1} sx={{ marginBottom: '15px' }}>
            {CANNED_RESPONSE_FIELDS.map((field: any) => {
              return (
                <Chip
                  key={field}
                  label={`${field}`}
                  onClick={() => {
                    handleSetCustomFields(selectedForm, field);
                  }}
                />
              );
            })}
          </Stack>

          {formValues.cannedResponses?.map(
            (response: CannedResponse, index: number) => {
              return response.isDeleted ? null : (
                <Grid item xs={12} key={index}>
                  <TextField
                    fullWidth
                    multiline
                    variant="standard"
                    size="small"
                    type="text"
                    id={`cannedResponse-${index}`}
                    name={`cannedResponse-${index}`}
                    label={`Response #${index + 1}`}
                    onChange={(e: any) => {
                      setIsDirty(true);
                      handleEditResponses(index, e.target.value);
                    }}
                    value={response?.message}
                    InputLabelProps={{ shrink: true }}
                    onFocus={() => {
                      setSelectedForm(index);
                    }}
                  />

                  <IconButton
                    id={`delete-${index}`}
                    size="small"
                    edge="end"
                    aria-label="canned response"
                    aria-controls="canned-message"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => {
                      handleDeleteReponses(index);
                    }}
                  >
                    <DeleteIcon sx={{ color: red[500], fontSize: '30px' }} />
                  </IconButton>
                </Grid>
              );
            },
          )}

          <Grid item xs={12} sx={{ marginBottom: '15px' }}>
            <PrimaryButton
              title="Add Response"
              type="button"
              handleOnClick={handleAddResponses}
              variant="text"
              startIcon={<AddIcon />}
            />
          </Grid>
        </>
      ) : null}

      <div className={styles.action}>
        <PrimaryButton
          title={isEdit ? 'Save' : 'Add'}
          loading={loading}
          size="large"
          rounded
          type="button"
          handleOnClick={handleOnSubmit}
        />
      </div>

      <InviteModal
        title={`Invite as ${humanizeString(invitationInfo.role)}`}
        subtitle={`Are you sure you want to invite ${
          invitationInfo.name
        } as ${humanizeString(invitationInfo.role)}?`}
        open={openInviteModal}
        handleClose={handleCloseInviteModal}
        invitationInfo={invitationInfo}
        handleInvite={handleInviteSalesperson}
        loading={inviteLoading}
        errors={invitationErrors}
      />

      <ConfirmNavigationModal
        open={openConfirmNavModal}
        onClose={handleCancelNavigation}
        onConfirm={handleConfirmNavigation}
      />
    </Box>
  );
};

export default LocationForm;
