import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';
import ModalHeader from '../Modal/ModalHeader';
import { constantStringToHumanized } from '../../utils/stringModifier';
import { AdLead, Brand, ClientTetherClientHistory } from '../../types';
import { fetchClientHistory } from '../../services/clientTether';
import { dayJsInstance } from '../../utils/dayjsHelper';
import { useSelector } from 'react-redux';

interface LeadDetailsProps {
  onClose: () => void;
  lead: AdLead;
  brand?: Brand | null;
  crm?: 'client-tether' | null;
}

const LeadDetails: React.FC<LeadDetailsProps> = ({
  onClose,
  lead,
  brand = null,
  crm = null,
}) => {
  const timezone = useSelector((state: any) => state?.brandTimezone?.timezone);
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const isClientTether = crm === 'client-tether';
  const [clientHistory, setClientHistory] = useState<
    ClientTetherClientHistory[]
  >([]);
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isClientTether && brand && lead?.clientTetherClientId) {
      getClientHistory();
    }
  }, [isClientTether, brand, lead?.clientTetherClientId]);

  const getClientHistory = async () => {
    try {
      setHistoryLoading(true);

      const response = await fetchClientHistory(
        brand?._id,
        lead?.clientTetherClientId,
      );

      setClientHistory(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setHistoryLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: xsOnly ? '100%' : '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          // background: "#F1F0F0",
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
        padding: '20px',
      }}
    >
      <Grid container spacing={2}>
        {xsOnly ? (
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={onClose} type="button">
              <CloseIcon />
            </IconButton>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <ModalHeader title="Lead Details" />
        </Grid>

        {isClientTether ? (
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
            }}
          >
            <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
              Client Tether Client ID:
            </Typography>

            <Typography variant="body2">
              {lead?.clientTetherClientId}
            </Typography>
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
          }}
        >
          <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
            Lead ID:
          </Typography>

          <Typography variant="body2">{lead?.id}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
          }}
        >
          <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
            Ad ID:
          </Typography>

          <Typography variant="body2">{lead?.adId}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {lead?.fieldData?.length > 0 ? (
          <>
            {lead?.fieldData?.map((data: any) => {
              return (
                <Grid
                  item
                  xs={12}
                  sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="body2"
                    mr={1}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {constantStringToHumanized(data?.name)}:
                  </Typography>

                  <Typography variant="body2">{data?.values[0]}</Typography>
                </Grid>
              );
            })}
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
              No other data to show
            </Typography>
          </Grid>
        )}

        {isClientTether ? (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', textAlign: 'center' }}
              >
                Client History
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  border: 'solid',
                  backgroundColor: '#d9d9d933',
                  minHeight: `500px`,
                  maxHeight: `500px`,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  '&::-webkit-scrollbar': {
                    width: '10px',
                  },
                  '&::-webkit-scrollbar-track': {
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  },

                  '&::-webkit-scrollbar-thumb': {
                    background: DEFAULT_COLOR_THEME,
                    borderRadius: '18px',

                    '&:hover': {
                      background: DEFAULT_COLOR_THEME,
                    },
                  },
                  display: historyLoading ? 'flex' : '',
                  alignItems: historyLoading ? 'center' : '',
                  justifyContent: historyLoading ? 'center' : '',
                }}
              >
                {' '}
                {!historyLoading ? (
                  <Grid container spacing={1} sx={{ padding: '5px' }}>
                    {clientHistory.map((history: ClientTetherClientHistory) => {
                      if (history.type === 'Email sent') return <></>;

                      return (
                        <>
                          <Grid item xs={12} sx={{ flexDirection: 'column' }}>
                            <Typography component="div" variant="caption">
                              {dayJsInstance(history.timestamp)
                                .tz(timezone)
                                .format('MMM D, YYYY h:mm A')}
                            </Typography>

                            <Typography
                              component="div"
                              variant="caption"
                              fontWeight="bold"
                            >
                              {history.type}
                            </Typography>

                            <Typography component="div" variant="caption">
                              {history.content}
                            </Typography>

                            <Typography
                              component="div"
                              variant="caption"
                              fontWeight="bold"
                            >
                              User:{' '}
                              <Typography variant="caption">
                                {history.user_name}
                              </Typography>
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                ) : (
                  <CircularProgress color="inherit" size={20} />
                )}
              </Box>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
};

export default LeadDetails;
