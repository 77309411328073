import {
  Box,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  ClientTetherSettingsSalesCycle,
  CrmSetting,
} from '../../../../types/ICrm';
import { Brand, ChangeEventType } from '../../../../types';
import { FloatingButton } from '../../../Buttons';
import { createOrUpdateCrmSettings } from '../../../../services/crm';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';
import ModalHeader from '../../../Modal/ModalHeader';
import { orderArrayOfObject } from '../../../../utils/arrayFormatter';
import { useForm } from 'react-hook-form';

interface ClientTetherSalesCycleFormProps {
  brand: Brand;
  formValues: CrmSetting;
  setFormValues: Dispatch<SetStateAction<CrmSetting>>;
  setCrmSettings: Dispatch<SetStateAction<CrmSetting>>;
  onClose: () => void;
}

const ClientTetherSalesCycleForm: React.FC<ClientTetherSalesCycleFormProps> = ({
  brand,
  formValues,
  setFormValues,
  setCrmSettings,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnChange = (e: ChangeEventType, salesCycleId: string) => {
    clearErrors(salesCycleId);
    let temp: CrmSetting = { ...formValues };

    temp?.clientTetherSettings?.salesCycles?.forEach(
      (salesCycle: ClientTetherSettingsSalesCycle) => {
        if (salesCycle.salesCycleId === salesCycleId) {
          salesCycle.closeRatio = parseInt(e.target.value);
        }
      },
    );

    setFormValues(temp);
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);

      const response = await createOrUpdateCrmSettings(brand?._id, formValues);

      setCrmSettings(response.data);
      setFormValues(response.data);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Sales cycle updated successfully',
        }),
      );
      onClose();
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ width: '300px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ModalHeader title="Sales Cycle Setup" />
          </Grid>

          {orderArrayOfObject(
            formValues?.clientTetherSettings?.salesCycles || [],
            'order',
            'asc',
          )?.map((salesCycle: ClientTetherSettingsSalesCycle) => {
            return (
              <Grid item xs={12} key={salesCycle.salesCycleId}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {salesCycle.salesCycleName}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          {...register(salesCycle.salesCycleId, {
                            required: true,
                          })}
                          variant="standard"
                          type="number"
                          id={salesCycle.salesCycleId}
                          name={salesCycle.salesCycleId}
                          label="Close Ratio"
                          onChange={(e: ChangeEventType) =>
                            handleOnChange(e, salesCycle.salesCycleId)
                          }
                          value={salesCycle?.closeRatio}
                          placeholder="Close ratio"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          sx={{
                            width: '100px',
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                              {
                                display: 'none',
                              },
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                          }}
                          error={!!errors[salesCycle.salesCycleId]}
                          helperText={
                            errors?.[salesCycle.salesCycleId] &&
                            'Close ratio is required'
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <FloatingButton
          title="Save"
          onClick={handleSubmit(handleOnSubmit)}
          loading={loading}
          bottom="0px"
          right="0px"
          sticky
          stickyPosition="right"
        />
      </Box>
    </>
  );
};

export default ClientTetherSalesCycleForm;
