import React, { ReactNode, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Reports.module.scss';
import PageHeader from '../../components/PageHeader';
import { AdLead, Brand, ClientTetherSalesCycleReport } from '../../types';
import { useSelector } from 'react-redux';
import {
  fetchSalesCycleReport,
  fetchSalesCycleReportByAds,
} from '../../services/clientTether';
import ClientTetherSalesCycleReportTable from '../../components/Tables/Pipelinez/ClientTetherSalesCycleReportTable';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { DrawerMenu } from '../../components/Menu';
import LeadDetails from '../../components/Details/LeadDetails';
import { PrimaryButton } from '../../components/Buttons';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import { TbCreditCardPay } from 'react-icons/tb';
import DashboardItem from '../../components/Cards/Billing/DashboardItem';
import { BsGraphUpArrow } from 'react-icons/bs';
import FacebookAdDetails from '../../components/Details/Ads/FacebookAdDetails';
import CircularLoading from '../../components/CircularLoading';

interface PipelinezDashboardItem {
  value: number;
  label: string;
  isCurrency: boolean;
  isPercentage: boolean;
  icon: ReactNode;
}

const Pipelinez: React.FC = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const [salesCycleReports, setSalesCycleReports] =
    useState<ClientTetherSalesCycleReport>(null);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [pipelinezItems, setPipelinezItems] = useState<
    PipelinezDashboardItem[]
  >([
    {
      value: 0,
      label: 'Value',
      isCurrency: true,
      isPercentage: false,
      icon: <AttachMoneyOutlinedIcon sx={{ fontSize: '30px' }} />,
    },
    {
      value: 0,
      label: 'Spend',
      isCurrency: true,
      isPercentage: false,
      icon: <TbCreditCardPay size={30} />,
    },
    {
      value: 0,
      label: 'Leads',
      isCurrency: false,
      isPercentage: false,
      icon: <GroupAddOutlinedIcon sx={{ fontSize: '30px' }} />,
    },
    {
      value: 0,
      label: 'ROI',
      isCurrency: false,
      isPercentage: true,
      icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: '30px' }} />,
    },
    {
      value: 0,
      label: 'Cost-per-Sale',
      isCurrency: true,
      isPercentage: false,
      icon: <BsGraphUpArrow size={30} />,
    },
  ]);
  const [selectedLead, setSelectedLead] = useState<AdLead>(null);
  const [openLeadDetails, setOpenLeadDetails] = useState<boolean>(false);
  const [showByAds, setShowByAds] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedAdId, setSelectedAdId] = useState<string>(null);
  const [openAdDetails, setOpenAdDetails] = useState<boolean>(false);

  useEffect(() => {
    if (showByAds) {
      getSalesCycleReportByAds();
    } else {
      getSalesCycleReport();
    }
  }, [location, brand, showByAds]);

  const getSalesCycleReport = async () => {
    try {
      setFetchLoading(true);

      const response = await fetchSalesCycleReport((location || brand)?._id);
      console.log(response);

      setSalesCycleReports(response);
      let tempDashboardItems: PipelinezDashboardItem[] = [];
      pipelinezItems.forEach((item: PipelinezDashboardItem) => {
        item.value = getDashboardItemValue(item.label, response);

        tempDashboardItems = [...tempDashboardItems, item];
      });

      setPipelinezItems(tempDashboardItems);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getSalesCycleReportByAds = async () => {
    try {
      setFetchLoading(true);
      const response = await fetchSalesCycleReportByAds(
        (location || brand)?._id,
      );

      setSalesCycleReports(response);
      let tempDashboardItems: PipelinezDashboardItem[] = [];
      pipelinezItems.forEach((item: PipelinezDashboardItem) => {
        item.value = getDashboardItemValue(item.label, response);

        tempDashboardItems = [...tempDashboardItems, item];
      });

      setPipelinezItems(tempDashboardItems);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOpenLeadDetails = (lead: AdLead) => {
    setSelectedLead(lead);
    setOpenLeadDetails(true);
  };

  const handleCloseLeadDetails = () => {
    setSelectedLead(null);
    setOpenLeadDetails(false);
  };

  const handleChangeTableView = () => {
    setShowByAds((prev) => !prev);
    setExpandedKeys([]);
  };

  const getDashboardItemValue = (
    label: any,
    report: ClientTetherSalesCycleReport,
  ) => {
    if (label === 'Value') return report.totalValue;

    if (label === 'Spend') return report.totalSpend;

    if (label === 'Leads') return report.totalLeads;

    if (label === 'ROI') return report.roi;

    return report.totalSales > 0 ? report.totalSpend / report.totalSales : 0;
  };

  const handleCloseAdDetails = () => {
    setOpenAdDetails(false);
    setSelectedAdId(null);
  };

  return (
    <div className={styles.report}>
      <CircularLoading loading={fetchLoading} />

      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Pipelinez" />
        </div>

        <Grid container spacing={1} justifyContent="center" columns={12} mb={2}>
          {pipelinezItems.map((item: PipelinezDashboardItem, index: number) => {
            console.log(item);
            return (
              <Grid item xs={12} sm={'auto'} key={`pipeline-item-${index + 1}`}>
                <DashboardItem
                  endingValue={item.value}
                  label={item.label}
                  isMobile={xsOnly}
                  icon={item.icon}
                  isCurrency={item.isCurrency}
                  isPercentage={item.isPercentage}
                  decimals={0}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={1} mb={1}>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            <PrimaryButton
              title={`Group By ${showByAds ? 'Stage' : 'Ads'}`}
              type="button"
              handleOnClick={handleChangeTableView}
            />
          </Grid>
        </Grid>

        <ClientTetherSalesCycleReportTable
          data={salesCycleReports}
          loading={fetchLoading}
          onOpenLeadDetails={handleOpenLeadDetails}
          isByAds={showByAds}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
          setSelectedAdId={setSelectedAdId}
          setOpenAdDetails={setOpenAdDetails}
        />
      </div>

      <DrawerMenu
        anchor="right"
        open={openLeadDetails}
        onClose={handleCloseLeadDetails}
      >
        <LeadDetails
          lead={selectedLead}
          onClose={handleCloseLeadDetails}
          crm="client-tether"
          brand={location || brand}
        />
      </DrawerMenu>

      <DrawerMenu
        anchor="right"
        open={openAdDetails}
        onClose={handleCloseAdDetails}
      >
        <FacebookAdDetails
          brand={location || brand}
          adId={selectedAdId}
          onClose={handleCloseAdDetails}
          isMobile={xsOnly}
        />
      </DrawerMenu>
    </div>
  );
};

export default Pipelinez;
